import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import moment from "moment";

import ActionItemCard from '../ActionItemCard';
import IconButton from '../../../Components/Button/IconButton';
import { AppConfigDTO } from "../../../Models";

interface Props {
    appConfigs: AppConfigDTO[],
    onEdit: ((appConfig: AppConfigDTO) => void);
}
interface State { }
class AppConfigCard extends React.Component<Props, State> {
    render() {
        const { appConfigs } = this.props;

        return (
            <Grid container spacing={2}>
                {appConfigs.map((appConfig, i) => {
                    return (
                        <Grid key={`SC-${i}`} item xs={4}>
                            <ActionItemCard>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item md={10}>
                                        <Grid container spacing={1}>
                                            <Grid container item md={12} alignItems="center" justify="space-between" spacing={2}>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#181818' }}>{appConfig.appconfigname}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#606060' }}>{appConfig.appconfigvalue}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item md={12} alignItems="center" justify="space-between" spacing={2}>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#181818' }}>{'HonoraryRuleChangeDate'}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#606060' }}>{moment(appConfig.honoraryrulechangedate).format('YYYY-MM-DD')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item md={12} alignItems="center" justify="space-between" spacing={2}>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#181818' }}>{'NewHonoraryRuleValue'}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#606060' }}>{appConfig.newhonoraryrulevalue}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <IconButton iconType="Edit" onClick={() => this.onEditClick(appConfig)} />
                                    </Grid>
                                </Grid>
                            </ActionItemCard>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(appConfig: AppConfigDTO) {
        this.props.onEdit(appConfig);
    }
}

export default AppConfigCard;
