import React from "react";
import { Backdrop, CircularProgress } from '@material-ui/core';

interface Props {
    show: boolean;
    style?: React.CSSProperties;
}
interface State { }
class BackdropLoaderCircular extends React.Component<Props, State> {
    render() {
        const { show, style } = this.props;

        return (
            <Backdrop open={show} onClick={this.handleClose} style={{ ...{ zIndex: 99 }, ...style }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    handleClose = () => { }
}

export default BackdropLoaderCircular;