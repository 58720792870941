import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

interface Props {
  title: string;
}
interface State {}
class DashboardCard extends React.Component<Props, State> {
  render() {
    const { title } = this.props;

    return (
      <Card
        style={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #EBEBEB",
          borderRadius: "11px",
          opacity: 1,
        }}
      >
        <CardContent>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#181818",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          {this.props.children}
        </CardContent>
      </Card>
    );
  }
}

export default DashboardCard;
