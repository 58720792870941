import React from "react";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExceptionManagementFilterModel from "./ExceptionManagementFilterModel";

interface Props {
  label: string;
  exceptionRateFilter: ExceptionManagementFilterModel;
  onFilterChange: (exceptionRateFilter: ExceptionManagementFilterModel) => void;
}
interface State {}
class ExceptionManagementFilter extends React.Component<Props, State> {
  render() {
    const { label, exceptionRateFilter } = this.props;

    return (
      <SearchFilter
        label={label}
        value={exceptionRateFilter.searchText}
        onChange={this.handleSearchFilterChange}
      />
    );
  }

  handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
    const { exceptionRateFilter, onFilterChange } = this.props;

    exceptionRateFilter.searchText = searchFilter.searchText;
    onFilterChange(exceptionRateFilter);
  };
}

export default ExceptionManagementFilter;
