import React from "react";
import { Chip, Grid, IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { FieldType } from "../../../Common/Enums";
import { QuestionnaireDTO, ContentfulQuestion, FacultyQuestDTO } from "../../../Models";
import LightTooltip from "../../../Components/Tooltip/LightTooltip";

const GridItem: React.FC<{ question: ContentfulQuestion, answers: FacultyQuestDTO[] }> = ({ question, answers }) => {
    let answerLabel = answers[0]?.answer;
    const contentfulAnswer = question.questionAnswers.find(p => p.answerOptions === answers[0]?.answer);
    let ignorePDF = false;

    if (question.config) {
        if ('ignorePDF' in question.config) {
            ignorePDF = question.config.ignorePDF as boolean;
        }
    }

    if (contentfulAnswer) {
        answerLabel = contentfulAnswer.answerText ? contentfulAnswer.answerText : contentfulAnswer.answerOptions;
    }

    let answerComponent = <Typography style={{ fontSize: 16, fontWeight: 400, color: '#1A1A1A' }}>
        {answerLabel}
    </Typography>;

    if (question.responseType === FieldType[FieldType.MultiDropdown]
        || question.responseType === FieldType[FieldType.MultiCheckbox]) {
        answerComponent = <Grid container spacing={1}>
            {answers.map((a, i) => {
                const contentfulAnswer = question.questionAnswers.find(p => p.answerOptions === a.answer);
                let label = a.answer;

                if (contentfulAnswer) {
                    label = contentfulAnswer.answerText ? contentfulAnswer.answerText : contentfulAnswer.answerOptions;
                }

                return (
                    <Grid item key={`QROF-AGI-${i}`}>
                        <Chip label={label} variant="outlined" />
                    </Grid>
                );
            })}
        </Grid>;
    }

    return (
        <Grid item md={12} {...(ignorePDF && { 'data-html2canvas-ignore': true })}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography style={{ fontSize: 16, fontWeight: 500, color: '#5D5D5D' }}>
                                {question.question}
                            </Typography>
                        </Grid>
                        {question.helpText ?
                            <Grid item>
                                <LightTooltip title={question.helpText}>
                                    <IconButton style={{ padding: 0, marginLeft: 5 }}>
                                        <FontAwesomeIcon icon={faInfoCircle} size="xs" color="#FFA49D" />
                                    </IconButton>
                                </LightTooltip>
                            </Grid> : null}
                    </Grid>
                </Grid>
                <Grid item>
                    {answerComponent}
                </Grid>
            </Grid>
        </Grid>
    );
};

interface Props {
    questionnaire?: QuestionnaireDTO;
}
interface State { }
class QuestionnaireReadOnlyForm extends React.Component<Props, State> {
    render() {
        const { questionnaire } = this.props;

        return (
            <Grid container justify="flex-start" spacing={7}>
                {questionnaire && questionnaire.questionnaire.map((q, i) => {
                    const answers = questionnaire.facultyQuests.filter(p => p.questionno === q.id);

                    return answers.length ? <GridItem key={`QROF-${i}`} question={q} answers={answers} /> : null;
                })}
            </Grid>
        );
    }
}

export default QuestionnaireReadOnlyForm;
