import React from "react";
import moment from "moment";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody
} from "@material-ui/core";

import { RateExceptionReportModel } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table//StyledTableRow";

interface Props {
    exceptionRateReportData: RateExceptionReportModel;
}
interface State { }
class ExceptionRateReportTable extends React.Component<Props, State> {
    render() {
        const { exceptionRateReportData } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="Faculty ID" minWidth={100} />
                            <TableHeaderCell label="PPQ Submission Date" minWidth={170} />
                            <TableHeaderCell label="Date Exception Entered" minWidth={170} />
                            <TableHeaderCell label="Approval Notice Date" minWidth={170} />
                            <TableHeaderCell label="Request Status" minWidth={170} />
                            <TableHeaderCell label="FN" />
                            <TableHeaderCell label="LN" />
                            <TableHeaderCell label="HCP Type" />
                            <TableHeaderCell label="Specialty" />
                            <TableHeaderCell label="Points" />
                            <TableHeaderCell label="Lilly Group" minWidth={170} />
                            <TableHeaderCell label="Lilly Exception Rate" minWidth={170} />
                            <TableHeaderCell label="Lilly Tier" minWidth={170} />
                            <TableHeaderCell label="BI Alliance Group" minWidth={190} />
                            <TableHeaderCell label="BI Alliance Exception Rate" minWidth={190} />
                            <TableHeaderCell label="BI Alliance Tier" minWidth={190} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exceptionRateReportData.facultyData.map((row) => {
                            const facultyException = exceptionRateReportData.exceptionData.find(p => p.facultyid === row.facultyid);
                            let approvalNoticeDate = '';
                            let requestStatus = '';

                            if (facultyException) {
                                if (facultyException.fmvadminteamactiondate) {
                                    approvalNoticeDate = moment(facultyException.fmvadminteamactiondate).format('MM-DD-YYYY');
                                }

                                requestStatus = facultyException.exceptiondecision
                                    ? facultyException.exceptiondecision
                                    : facultyException.fmvexceptioncommittestatus
                                        ? facultyException.fmvexceptioncommittestatus
                                        : facultyException.vpapproverstatus
                                            ? facultyException.vpapproverstatus
                                            : facultyException.exceptionrequeststatus
                                                ? facultyException.exceptionrequeststatus
                                                : facultyException.fmvadminteamstatus;
                            }

                            return (
                                <StyledTableRow key={row.id}>
                                    <TableBodyCell label={row.facultyid} />
                                    <TableBodyCell label={moment(row.ppqsubmissiondate).format('MM-DD-YYYY')} />
                                    <TableBodyCell label={moment(row.exceptionratedate).format('MM-DD-YYYY')} />
                                    <TableBodyCell label={approvalNoticeDate} />
                                    <TableBodyCell label={requestStatus} />
                                    <TableBodyCell label={row.firstname} />
                                    <TableBodyCell label={row.lastname} />
                                    <TableBodyCell label={row.hcptype} />
                                    <TableBodyCell label={row.specialty} />
                                    <TableBodyCell label={row.points} />
                                    <TableBodyCell label={row.lillygroup} />
                                    <TableBodyCell label={row.lillyexceptionrate} />
                                    <TableBodyCell label={row.lillytier} />
                                    <TableBodyCell label={row.bigroup} />
                                    <TableBodyCell label={row.biexceptionrate} />
                                    <TableBodyCell label={row.bitier} />
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default ExceptionRateReportTable;