import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import { ExceptionManagementDTO } from "../../Models";
import TableHeaderCell from "../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../Components/Table/TableBodyCell";
import StyledTableRow from "../../Components/Table/StyledTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../Components/Text/PageHeader";
interface Props {
  requestData: ExceptionManagementDTO[];
}
interface State {}
class MyRequestTable extends React.Component<Props, State> {
  handleBackClick = () => {
    window.location.href = "/fmvform";
    localStorage.removeItem("Id");
  };
  render() {
    const { requestData } = this.props;
    const sortedReportData = [...requestData].sort((a: any, b: any) => +new Date(a.createdon) - +new Date(b.createdon))
    return (
      <>
        <Grid container direction="row">
          <Grid container item style={{ marginTop: 5, marginRight: 20 }}>
            <Grid container direction="row" style={{ marginBottom: 10 }}>
              <Grid item style={{ marginRight: 5 }}>
                <FontAwesomeIcon
                  onClick={this.handleBackClick}
                  style={{
                    color: "#ee3a29",
                    marginBottom: 10,
                    marginRight: 2,
                    cursor: "pointer",
                  }}
                  icon={faArrowLeft}
                  size="lg"
                />
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: "#313131",
                    fontWeight: 600,
                  }}
                >
                  Back
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item>
            <PageHeader label="My Exception Request" />
          </Grid>
        </Grid>

        {/* <Grid container direction="row" alignContent="center" spacing={2}>
          <Link to="/fmvform">
            <FontAwesomeIcon
              style={{
                color: "#ee3a29",
                marginBottom: 10,
                marginRight: 10,
              }}
              icon={faArrowLeft}
              size="lg"
            />
          </Link>
          <Typography
            style={{
              color: "#313131",
              marginBottom: 10,
              marginRight: 25,
              fontWeight: 600,
            }}
          >
            Back
          </Typography>

          <PageHeader label="My Exception Request" />
        </Grid> */}
        <Divider
          orientation="horizontal"
          style={{ marginTop: 10, marginBottom: 20 }}
        />

        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#EEEEEE" }}>
              <TableRow>
                <TableHeaderCell label="Request ID" />
                <TableHeaderCell label="Faculty ID" />
                <TableHeaderCell label="Date Requested" />
                <TableHeaderCell label="First Name" />
                <TableHeaderCell label="Last Name" />
                <TableHeaderCell label="Approval Notice Date" />
                <TableHeaderCell label="Request Status" />
                <TableHeaderCell label="Modified" />
                <TableHeaderCell label="Requester Email" />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedReportData.map((row, i) => (
                <StyledTableRow key={i}>
                  <TableBodyCell
                    label={
                      row.exceptiondecision ===
                      "Exception Approved By Committee Member" ? (
                        <Link to={`/fmvreadonlyform/${row.requestid}`}>
                          {row.requestid}
                        </Link>
                      ) : (
                        <Link to={`/fmvform/${row.requestid}`}>
                          {row.requestid}
                        </Link>
                      )
                    }
                  />
                  <TableBodyCell label={row.facultyid} />
                  <TableBodyCell
                    label={moment(row.createdon).format("MM-DD-YYYY")}
                  />
                  <TableBodyCell label={row.firstname} />
                  <TableBodyCell label={row.lastname} />
                  <TableBodyCell
                    label={
                      row.fmvadminteamactiondate ? (
                        moment(row.fmvadminteamactiondate).format("MM-DD-YYYY")
                      ) : (
                        <></>
                      )
                    }
                  />
                  <TableCell>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: row.exceptiondecision
                          ? "#828282"
                          : row.fmvexceptioncommittestatus
                          ? "#828282"
                          : row.vpapproverstatus
                          ? "#828282"
                          : row.exceptionrequeststatus === "Pending"
                          ? "#FF0000"
                          : "#828282",
                      }}
                    >
                      {row.exceptiondecision
                        ? row.exceptiondecision
                        : row.fmvexceptioncommittestatus
                        ? row.fmvexceptioncommittestatus
                        : row.vpapproverstatus
                        ? row.vpapproverstatus
                        : row.exceptionrequeststatus
                        ? row.exceptionrequeststatus
                        : row.fmvadminteamstatus}
                    </Typography>
                  </TableCell>
                  <TableBodyCell
                    label={
                      row.updatedon ? (
                        moment(row.updatedon).format("MM-DD-YYYY")
                      ) : (
                        <></>
                      )
                    }
                  />
                  <TableBodyCell label={row.requesteremail} />
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default MyRequestTable;
