import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Layout from './Components/Layout';
import FacultyLayout from './Components/FacultyLayout';
import AdminRoute from './Components/Route/AdminRoute';
import UserRoute from './Components/Route/UserRoute';

import {
  DashboardPage,
  ActionItemPage,
  QuestionnaireManagementPage,
  SpecialtyCredentialManagementPage,
  ReportPage,
  AuthLoginPage,
  AuthCallbackPage,
  FacultyResourcePage,
  AccessDeniedPage,
  RateExceptionPage,  
  FMVRateExceptionForm, 
  RateExceptionReadOnlyForm,
  MyRequest

} from './Pages';

function App() {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path={[
          "/dashboard", "/actionitem", "/questionnairemanagement",
          "/rateupdateexceptions", "/specialtycredentialmanagement", "/reports",
           "/fmvform", "/fmvform/:id", "/fmvreadonlyform/:id",
          "/myrequest/:id"
        ]}>
          <Layout>
            <Switch>
              <AdminRoute path="/dashboard" exact={true} component={DashboardPage} />
              <AdminRoute path="/actionitem" component={ActionItemPage} />
              <AdminRoute path="/questionnairemanagement" component={QuestionnaireManagementPage} />
              <AdminRoute path="/rateupdateexceptions" component={RateExceptionPage} />
              <AdminRoute path="/specialtycredentialmanagement" component={SpecialtyCredentialManagementPage} />
              <AdminRoute path="/reports" component={ReportPage} />
             
              <UserRoute path="/fmvform" component={FMVRateExceptionForm} />
              <UserRoute path="/fmvreadonlyform/:id" component={RateExceptionReadOnlyForm} />
              <UserRoute path="/myrequest/:id" component={MyRequest} />
              <UserRoute path="/fmvform/:id" component={FMVRateExceptionForm} />
            </Switch>
          </Layout>
        </Route>
        <Route path={[
          "/auth/login", "/auth/callback", "/auth/accessdenied",
        ]}>
          <Layout showMenu={false}>
            <Switch>
              <Route path="/auth/login" component={AuthLoginPage} />
              <Route path="/auth/callback" component={AuthCallbackPage} />
              <Route path="/auth/accessdenied" component={AccessDeniedPage} />
            </Switch>
          </Layout>
        </Route>
        <Route path={[
          "/facultyresource/questionnaire",
        ]}>
          <FacultyLayout>
            <Route path="/facultyresource/questionnaire" component={FacultyResourcePage} />
          </FacultyLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;