import React from "react";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    WithStyles,
    withStyles,
    Theme,
    ListItemIcon,
} from "@material-ui/core";
import { Pagination } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { FacultyDTO, FacultyPPQDataDTO } from "../../../Models";
import Utils from "../../../Common/Utils";
import { TabModel } from "../TabModel";
import { QuestionnaryStatus } from "../../../Common/Enums";
import FacultyFilterModel from "../FacultyFilterModel";
import LightTooltip from "../../../Components/Tooltip/LightTooltip";

const styles = (theme: Theme) => ({
    selected: {
        backgroundColor: '#FFF5F5 !important',
        borderRight: '3px solid #EE3A29',
    },
});

interface Props extends WithStyles<typeof styles> {
    currentTab: TabModel;
    facultyPPQData: FacultyPPQDataDTO[];
    faculties: FacultyDTO[];
    selectedFaculty?: FacultyDTO;
    facultyFilter: FacultyFilterModel;
    onChange: ((selected: FacultyDTO) => void);
}
interface State {
    page: number;
    pageSize: number;
}
class FacultyPanel extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.facultyFilter !== this.props.facultyFilter) {
            const { page } = this.state;

            if (page !== 1) {
                this.setState({ page: 1 });
            }
        }
    }

    render() {
        const { facultyPPQData, faculties, selectedFaculty, onChange, classes } = this.props;
        const { page, pageSize } = this.state;

        const paginatedData = Utils.paginate(faculties, pageSize, page);

        return (
            <div>
                <List style={{ cursor: 'pointer' }}>
                    {paginatedData.map((faculty, i) => {
                        const isSelected = selectedFaculty ? selectedFaculty.id === faculty.id : false;
                        const fullName = `${faculty.firstname} ${faculty.lastname}`;
                        let hcpTypeSpecialty = '';
                        const facultyPPQ = facultyPPQData.find(p => p.facultyid === faculty.facultyid);
                        let isException = false;

                        if (faculty.hcptype) {
                            hcpTypeSpecialty = faculty.hcptype;
                        }

                        if (faculty.specialty) {
                            hcpTypeSpecialty += ` | ${faculty.specialty}`;
                        }

                        if (facultyPPQ) {
                            isException = facultyPPQ.ppqsubmissionstatus === QuestionnaryStatus.EXCEPTION;
                        } 

                        return (
                            <ListItem key={`faculty${i}`} divider alignItems="flex-start" classes={{ selected: classes.selected }}
                                onClick={() => onChange(faculty)} selected={isSelected}>
                                <ListItemAvatar>
                                    <Avatar style={{ height: '51px', width: '51px', marginRight: 15 }} />
                                </ListItemAvatar>
                                <ListItemText disableTypography
                                    primary={
                                        <React.Fragment>
                                            <Typography style={{ fontSize: 16, fontWeight: 500, color: '#383838' }}>
                                                {fullName}
                                            </Typography>
                                            <Typography style={{ fontSize: 14, fontWeight: 400, color: '#383838' }}>
                                                {faculty.facultyid}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography style={{ fontSize: 14, fontWeight: 400, color: '#747474', marginTop: 10, marginBottom: 10 }}>
                                                {hcpTypeSpecialty}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                                {isException ? <ListItemIcon>
                                    <LightTooltip title={facultyPPQ ? facultyPPQ.exceptiontype : ''} placement="right-end">
                                        <div>
                                            <FontAwesomeIcon icon={faExclamationCircle} size="lg" color="#EE3A29" />
                                        </div>
                                    </LightTooltip>
                                </ListItemIcon> : null}
                            </ListItem>
                        );
                    })}
                </List>
                <br />
                {faculties.length > 0 ? <Pagination size="medium"
                    count={Math.ceil(faculties.length / pageSize)} page={page} onChange={this.handlePageChange} /> : null}
            </div>
        )
    }

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ page: value });
    }
}

export default withStyles(styles)(FacultyPanel);
