import React from "react";
import { Box, Grid, Divider } from "@material-ui/core";

import { FMVRateExceptionService } from "../../../Services";
import { ExceptionManagementDTO } from "../../../Models";
import PageHeader from "../../../Components/Text/PageHeader";
import ExceptionTable from "./ExceptionManagementTable";
import ExceptionManagementFilter from "./ExceptionManagementFilter";
import ExceptionManagementFilterModel from "./ExceptionManagementFilterModel";
import SingleAutoComplete from "../../../Components/Select/SingleAutoComplete";
import { statusData } from "../../../Data/FMVRateExceptionData";
import SelectModel from "../../../Components/Select/SelectModel";

interface Props {}
interface State {
  exceptionFacultyFilter: ExceptionManagementFilterModel;
  exceptionRequestFilter: ExceptionManagementFilterModel;
  exceptionData: ExceptionManagementDTO[];
  selectFilterValue: string | number;
}
class ExceptionManagement extends React.Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      exceptionFacultyFilter: new ExceptionManagementFilterModel(),
      exceptionRequestFilter: new ExceptionManagementFilterModel(),
      exceptionData: [],
      selectFilterValue: "",
    };
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const {
      exceptionFacultyFilter,
      exceptionRequestFilter,
      exceptionData,
      selectFilterValue,
    } = this.state;

    return (
      <Box m={2}>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <PageHeader label="Exception Request" />
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          style={{ marginTop: 10, marginBottom: 20 }}
        />
        <Grid container style={{ marginBottom: 20 }}>
          <Grid item container xs={9}>
            <Grid item xs={3} style={{ marginRight: 15 }}>
              <ExceptionManagementFilter
                label="Search by FacultyId"
                exceptionRateFilter={exceptionFacultyFilter}
                onFilterChange={this.handleFacultyFilterChange}
              />
            </Grid>

            <Grid item xs={3} style={{ marginRight: 15 }}>
              <ExceptionManagementFilter
                label="Search by RequestId"
                exceptionRateFilter={exceptionRequestFilter}
                onFilterChange={this.handleRequestFilterChange}
              />
            </Grid>
            <Grid item xs={3}>
              <SingleAutoComplete
                id="selectFilterValue"
                label="Select Status"
                values={statusData}
                selectedValue={selectFilterValue}
                isRounded
                isRequired={false}
                onChange={this.handleSelectFilterChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="row">
          <Grid item xs={12}>
            <ExceptionTable exceptionData={exceptionData} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  loadData = async () => {
    const exceptionData = await FMVRateExceptionService.getAll();
    const exceptionFilteredData = exceptionData.filter(
      (p) => p.exceptionrequeststatus !== "Saved"
    );
    // console.log('exceptionFilteredData', exceptionFilteredData);
    this.setState({ exceptionData: exceptionFilteredData });
    // console.log("data", exceptionData);
  };

  handleFacultyFilterChange = (
    exceptionRateFilter: ExceptionManagementFilterModel
  ) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    const facultyid = exceptionRateFilter.searchText;
    this.setState({ exceptionFacultyFilter: exceptionRateFilter }, () => {
      this.timer = setTimeout(async () => {
        if (facultyid) {
          const exceptionData = await FMVRateExceptionService.getByFacultyId(
            facultyid
          );
          // console.log("exceptionData", exceptionData);
          // console.log("exceptionRateFilter", exceptionRateFilter.searchText);

          const exceptionFilteredData = exceptionData.filter(
            (p) => p.exceptionrequeststatus !== "Saved"
          );
          this.setState({ exceptionData: exceptionFilteredData });
        } else {
          this.loadData();
        }
      }, 1000);
    });
  };

  handleRequestFilterChange = (
    exceptionRateFilter: ExceptionManagementFilterModel
  ) => {
    const requestid = exceptionRateFilter.searchText;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState({ exceptionRequestFilter: exceptionRateFilter }, () => {
      this.timer = setTimeout(async () => {
        if (requestid) {
          const exceptionRequestData =
            await FMVRateExceptionService.getByRequestId(requestid);
          const exceptionFilteredData =
            exceptionRequestData?.exceptionrequeststatus !== "Saved"
              ? exceptionRequestData
              : null;
          // console.log("exceptionFilteredData", exceptionFilteredData);
          const exceptionData: any[] = [];
          if (exceptionFilteredData) {
            exceptionData.push(exceptionFilteredData);
            this.setState({
              exceptionData: exceptionData,
            });
          } else {
            this.setState({
              exceptionData: [],
            });
          }
        } else {
          this.loadData();
        }
      }, 1000);
    });
  };
  handleSelectFilterChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      //console.log("value", selected.value);
      if(selected.value) {
        const data = this.state.exceptionData.filter((row) => {
          const status = row.exceptiondecision
            ? row.exceptiondecision
            : row.fmvexceptioncommittestatus
            ? row.fmvexceptioncommittestatus
            : row.vpapproverstatus
            ? row.vpapproverstatus
            : row.exceptionrequeststatus
            ? row.exceptionrequeststatus
            : row.fmvadminteamstatus;
          return status === selected.value;
        });
        this.setState({selectFilterValue:selected.value, exceptionData:data})
      } else {
        this.loadData();
      }
    }
  };
}

export default ExceptionManagement;
