import { createClient, ContentfulClientApi } from "contentful";
import HttpsProxyAgent from "https-proxy-agent";

import Utils from "../Common/Utils";
import config from "../config.json";
import { ContentfulAnswer, ContentfulQuestion, BrandModel } from "../Models";

const env = Utils.getEnvVars();

class ContentfulService {
  private readonly _contentfulClient: ContentfulClientApi;

  constructor() {
    const proxyAgent = HttpsProxyAgent(process.env.PROXY ?? "");

    this._contentfulClient = createClient({
      space: "qdvjdn9u3mg7" ?? "",
      accessToken: "qXMboz9M7zmwlm8aYfDgQ3nyZW5wHE1swVqQNKoHHfs" ?? "",
      environment: env.Contentful.ENVIRONMENT,
      httpsAgent: proxyAgent,
      host: "https://cdn.contentful.com" ?? "",
    });
  }

  async getQuestions(): Promise<ContentfulQuestion[]> {
    const response =
      await this._contentfulClient.getEntries<ContentfulQuestion>({
        content_type: config.Contentful.contentTypeIds.question,
        limit: config.Contentful.defaultLimit,
      });

    return response.items.map((p) => p.fields);
  }

  async getAnswers(): Promise<ContentfulAnswer[]> {
    const response = await this._contentfulClient.getEntries<any>({
      content_type: config.Contentful.contentTypeIds.answer,
      limit: config.Contentful.defaultLimit,
    });

    const answers = response.items.map((p) => p.fields);

    answers.forEach((answer) => {
      answer.qid = answer.qid.fields.id;
    });

    return answers;
  }
  async getBrands(): Promise<BrandModel[]> {
    const response = await this._contentfulClient.getEntries<BrandModel>({
      content_type: config.Contentful.contentTypeIds.exceptionBusinessBrand,
      limit: config.Contentful.defaultLimit,
    });

    const answers = response.items.map((p) => p.fields);
    return answers;
  }
}

export default new ContentfulService();
