import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import SelectModel from "./SelectModel";
import { ValidationType } from "../../Common/Enums";
import ValidationUtils from "../../Common/ValidationUtils";

interface Props {
  id?: string;
  label: string;
  value?: string | number;
  values: SelectModel[];
  isRequired: boolean;
 validationType: ValidationType;
  onChange: (selected: SelectModel, targetId?: string, isValid?: boolean) => void;
}
interface State {
  helperText: string;
  value: string | number;
}
class SingleSelect extends React.Component<Props, State> {
    public static defaultProps = {
        
        isRequired: true,
        validationType: ValidationType.NONE,
      };
    
      constructor(props: Props | Readonly<Props>) {
        super(props);
    
        this.state = {
          helperText: "",
          value: props.value ? props.value : "",
        };
      }
    
  render() {
    const { id, label, value, values,isRequired} = this.props;

    const labelId = id ? id : label;

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
        required={isRequired}
          labelId={labelId + "label"}
          id={labelId}
          value={value}
          onChange={this.handleChange}
          label={label}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {values.map((v) => {
            return (
              <MenuItem key={v.value} value={v.value}>
                {v.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { id, values } = this.props;
    const value = event.target.value as string;

    const selectedValue = values.find((p) => p.value === value);

    if (selectedValue) {
      this.setState({ helperText: "" }, () => {
        const isValid = this.isValid();
        this.props.onChange(selectedValue, id, isValid);
      });
    }
  };
  isValid = () => {
    const { validationType, isRequired } = this.props;
    const { value } = this.state;

    let validationResult = ValidationUtils.validate(validationType, value);

    if (validationResult.isValid && isRequired) {
      validationResult = ValidationUtils.validate(
        ValidationType.REQUIRED,
        value
      );
    }

    if (!validationResult.isValid) {
      this.setState({ helperText: validationResult.message });
      return false;
    }

    return true;
  };
}

export default SingleSelect;
