import React from "react";
import { TextField, WithStyles, withStyles, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown as ExpandMoreIcon } from "@fortawesome/free-solid-svg-icons";

import { ValidationType } from "../../Common/Enums";
import ValidationUtils from "../../Common/ValidationUtils";
import SelectModel from "./SelectModel";

const styles = (theme: Theme) => ({
  notchedOutline: {
    border: "1px solid #EE3A29",
    borderRadius: "27px",
  },
});

interface Props extends WithStyles<typeof styles> {
  id?: string;
  label: string;
  selectedValue?: string | number;
  disabled: boolean;
  isRequired: boolean;
  values: SelectModel[];
  isRounded: boolean;
  inputValue?:string;
  validationType: ValidationType;
  onChange: (
    selected: SelectModel,
    targetId?: string,
    isValid?: boolean
  ) => void;
  onInputChange?: (value: string) => void;
}
interface State {
  helperText: string;
  value: string | number;
}
class SingleAutoComplete extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
    isRounded: false,
    isRequired: true,
    validationType: ValidationType.NONE,
  };

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      helperText: "",
      value: props.selectedValue ? props.selectedValue : "",
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { selectedValue: propsValue } = this.props;
    const { selectedValue: prevPropsValue } = prevProps;

    if (propsValue !== undefined && propsValue !== prevPropsValue) {
      this.setState({ value: propsValue });
    }
  }

  render() {
    const { id, label, values, isRounded, classes, onInputChange,inputValue, isRequired } = this.props;
    const { value, helperText } = this.state;

    const labelId = id ? id : label;
    // console.log("values", values);
    const selected : any = values.find((v) => v.value === value) ?? null;

    return (
      <Autocomplete
        limitTags={1}
        id={labelId}
        options={values}
        inputValue={inputValue}
        getOptionLabel={(option) => option.text}
        value={selected}
        renderOption={(option) => option.text}
        renderInput={(params) => (
          <TextField
            required={isRequired}
            {...params}
            label={label}
            variant="outlined"
            helperText={helperText}
            InputLabelProps={{
              style: { marginLeft: 5 },
            }}
            InputProps={{
              ...params.InputProps,
              ...{
                classes: isRounded ? classes : undefined,
              },
            }}
          />
        )}
        onChange={this.handleChange}
        popupIcon={
          <FontAwesomeIcon icon={ExpandMoreIcon} size="xs" color="#EE3A29" />
        }
        onInputChange={(event, value) => onInputChange?.(value)}
      />
    );
  }

  handleChange = (event: React.ChangeEvent<{}>, value: SelectModel | null) => {
    const { id, isRequired } = this.props;

    this.setState({ value: value ? value.value : "", helperText: "" }, () => {
      if (isRequired) {
        const validationResult = ValidationUtils.validate(
          ValidationType.REQUIRED,
          this.state.value
        );
        //console.log(validationResult);
        this.setState(
          {
            helperText: validationResult.isValid
              ? ""
              : validationResult.message,
          },
          () => {
            this.props.onChange(
              value ?? new SelectModel(),
              id,
              validationResult.isValid
            );
          }
        );
      } else {
        this.props.onChange(value ?? new SelectModel(), id, true);
      }
    });
  };
}

export default withStyles(styles)(SingleAutoComplete);
