import React from "react";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import RedButton from "../../Components/Button/RedButton";
import IconButton from "../../Components/Button/IconButton";
import moment from "moment";
import TextAreaInputField from "../../Components/TextInput/TextAreaInputField";
import {
  ExceptionManagementDTO,
  EmailDTO,
  CommitteeMemberManagementDTO,
  RateExceptionAuditDTO,
} from "../../Models";
import {
  FMVRateExceptionService,
  CommitteeMemberManagementService,
  AuthProviderService,
} from "../../Services";
import ModalDialog from "../../Components/Modal/ModalDialog";
import WhiteButton from "../../Components/Button/WhiteButton";
import Utils from "../../Common/Utils";
interface LabelProps {
  text: string;
}
const Label = (props: LabelProps) => {
  return (
    <Typography
      style={{
        fontWeight: 520,
        color: "#000000d6",
        marginRight: 30,
      }}
    >
      {props.text}
    </Typography>
  );
};

interface Props {
  rateExceptionData: ExceptionManagementDTO;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTextAreaInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onFocus: () => void;
  onBlur: () => void;
  focused: boolean;
}
interface State {
  viewVPDecision: boolean;
  //viewCommitteeDecision: boolean;
  committeeMemberData: CommitteeMemberManagementDTO[];
  vpApprovedDecision: boolean;
  vpDeniedDecision: boolean;
  vpDeniedError: boolean;
  vpMoreInfoError: boolean;
  vpMoreInfoDial: boolean;
  vpMoreInfoDecision: boolean;
  vpDeniedDial: boolean;
  // committeeApprovedDecision: boolean;
  // committeeDeniedDecision: boolean;
  // committeeMoreInfoDecision: boolean;
  toMailData: string;
  ccMailData: string;
  // committeeDeniedError: boolean;
  // committeeMoreInfoError: boolean;
  vpApprovedDial: boolean;
  // committeeApprovedDial: boolean;
  // committeeDeniedDial: boolean;
  // committeeMoreInfoDial: boolean;
  userEmail: string;
  //isToCommitteeMember: boolean;
  userRole: string;
}
class VPAndCommitteDecision extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      viewVPDecision: false,
      //viewCommitteeDecision: false,
      committeeMemberData: [],
      vpApprovedDecision: false,
      vpDeniedDecision: false,
      vpMoreInfoDecision: false,
      // committeeApprovedDecision: false,
      // committeeDeniedDecision: false,
      // committeeMoreInfoDecision: false,
      toMailData: "",
      ccMailData: "",
      vpDeniedError: false,
      //committeeDeniedError: false,
      vpMoreInfoError: false,
      //committeeMoreInfoError: false,
      vpMoreInfoDial: false,
      vpDeniedDial: false,
      vpApprovedDial: false,
      // committeeApprovedDial: false,
      // committeeDeniedDial: false,
      // committeeMoreInfoDial: false,
      userEmail: "",
      //isToCommitteeMember: false,
      userRole: "",
    };
  }
  async componentDidMount() {
    const userRole = localStorage.getItem("UserRole");
    if (userRole) {
      this.setState({ userRole });
    }
    const response = await CommitteeMemberManagementService.getAll();
    this.setState({ committeeMemberData: response });
    const { accessToken } = await AuthProviderService.getAccessToken();
    const loggedinUser = Utils.decodeJWT<any>(accessToken);
    this.setState({ userEmail: loggedinUser.preferred_username });
    if (
      loggedinUser.preferred_username ===
        this.props.rateExceptionData.vpapproveremail.toLowerCase() ||
      this.state.userRole === "LILLY_ADMIN"
    ) {
      this.setState({ viewVPDecision: true });
    }
    const toMemberData = this.state.committeeMemberData.filter(
      (p) => p.iscommitteemember === true
    );
    const ccMemberData = this.state.committeeMemberData.filter(
      (p) => p.iscommitteemember === false
    );
    // const isCommitteeMember = toMemberData.filter(
    //   (e) => e.email === loggedinUser.preferred_username
    // );
    // if (isCommitteeMember.length !== 0) {
    //   this.setState({ isToCommitteeMember: true, viewCommitteeDecision: true });
    // }
    const toMailData = toMemberData
      .map((p) => p.email)
      .join(",")
      .toString();
    const ccMailData = ccMemberData
      .map((p) => p.email)
      .join(",")
      .toString();
    this.setState({ toMailData, ccMailData });
    //console.log("committee", isCommitteeMember);
  }

  render(): React.ReactNode {
    const {
      viewVPDecision,
      vpApprovedDecision,
      vpDeniedDecision,
      vpMoreInfoDecision,
      vpDeniedError,
      vpMoreInfoError,
      vpMoreInfoDial,
      vpDeniedDial,
      vpApprovedDial,
      userEmail,
      userRole,
      // isToCommitteeMember,
      // viewCommitteeDecision,
      // committeeApprovedDecision,
      // committeeDeniedDecision,
      // committeeMoreInfoDecision,
      // committeeDeniedError,
      // committeeMoreInfoError,
      // committeeApprovedDial,
      // committeeDeniedDial,
      // committeeMoreInfoDial,
    } = this.state;
    const {
      rateExceptionData,
      onTextAreaInputChange,
      onFocus,
      onBlur,
      focused,
    } = this.props;
    //console.log("rateExceptionData", isToCommitteeMember);
    return (
      <>
        {userEmail === rateExceptionData.vpapproveremail.toLowerCase() ||
        userRole === "LILLY_ADMIN" ? (
          <Box style={{ marginBottom: 50 }}>
            <Grid container direction="row" justify="space-between">
              <Label text="Senior VP Approver Decision" />
              {viewVPDecision ? (
                <IconButton iconType="Minimize" onClick={this.handleVPClick} />
              ) : (
                <IconButton iconType="Add" onClick={this.handleVPClick} />
              )}
              {/* <IconButton iconType="Add" onClick={this.handleVPClick} /> */}
            </Grid>
            <Divider
              orientation="horizontal"
              style={{ marginTop: 10, marginBottom: 30 }}
            />
            {viewVPDecision ? (
              <Grid container>
                <Grid item container xs={12} style={{ marginBottom: 25 }}>
                  <Grid item xs={1}>
                    <RedButton
                      disabled={
                        rateExceptionData.vpapproverstatus === "Approved By VP"
                          ? true
                          : vpApprovedDecision
                      }
                      id="view"
                      label="Approved"
                      onClick={this.handleVPApprovedDail}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <RedButton
                      disabled={
                        rateExceptionData.vpapproverstatus === "Denied By VP"
                          ? true
                          : vpDeniedDecision
                      }
                      id="view"
                      label="Denied"
                      onClick={this.handleVPDeniedAction}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RedButton
                      disabled={
                        rateExceptionData.vpapproverstatus ===
                        "VP Requested More Information"
                          ? true
                          : vpMoreInfoDecision
                      }
                      id="view"
                      label="Request More Information"
                      onClick={this.handleVPRequestMoreInfoAction}
                    />
                  </Grid>
                </Grid>
                {rateExceptionData.vpapproveractiondate ? (
                  <Grid item xs={12}>
                    <Typography>
                      Action :
                      {`${moment(rateExceptionData.vpapproveractiondate).format(
                        "DD-MM-YYYY"
                      )}`}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {rateExceptionData.vpapprovercomment ? (
                  <>
                    <Grid item xs={12}>
                      <Label text="Comments:" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextAreaInputField
                        id="vpapprovercomment"
                        isDisabled={true}
                        focused={focused}
                        width="97.5%"
                        value={rateExceptionData.vpapprovercomment}
                        onChange={onTextAreaInputChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <ModalDialog
                  isOpen={
                    vpMoreInfoDial
                      ? vpMoreInfoDial
                      : vpDeniedDial
                      ? vpDeniedDial
                      : vpApprovedDial
                  }
                  title="Comments"
                  onClose={this.handleVPDialClose}
                  dialogWidth="md"
                >
                  <Grid style={{ marginBottom: 15 }}>
                    <TextAreaInputField
                      id="vpapprovercomment"
                      isRequired={false}
                      focused={focused}
                      width="97.5%"
                      value={rateExceptionData.vpapprovercomment}
                      onChange={onTextAreaInputChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />

                    {vpMoreInfoError ? (
                      <Typography
                        style={{
                          color: "#6d6e6e",
                        }}
                      >
                        Comments are required in the event of requiring more
                        information.
                      </Typography>
                    ) : (
                      <></>
                    )}
                    {vpDeniedError ? (
                      <Typography
                        style={{
                          color: "#6d6e6e",
                        }}
                      >
                        Comments are required in case of denial.
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Grid style={{ marginRight: 8 }}>
                      <RedButton
                        id="view"
                        label="Submit"
                        onClick={
                          vpMoreInfoDial
                            ? this.handleVPRequestMoreInfo
                            : vpDeniedDial
                            ? this.handleVPDenied
                            : this.handleVPApproved
                        }
                      />
                    </Grid>
                    <Grid>
                      <WhiteButton
                        label="Cancel"
                        onClick={this.handleVPDialClose}
                      />
                    </Grid>
                  </Grid>
                </ModalDialog>
              </Grid>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </>
      // {isToCommitteeMember ? (
      //   <Box style={{ marginBottom: 50 }}>
      //     <Grid container direction="row" justify="space-between">
      //       <Label text=" FMV Exception Committee Decision" />
      //       {viewCommitteeDecision?<IconButton iconType="Minimize" onClick={this.handleCommitteeClick} />:<IconButton iconType="Add" onClick={this.handleCommitteeClick} />}
      //       {/* <IconButton iconType="Add" onClick={this.handleCommitteeClick} /> */}
      //     </Grid>
      //     <Divider
      //       orientation="horizontal"
      //       style={{ marginTop: 10, marginBottom: 30 }}
      //     />
      //     {viewCommitteeDecision ? (
      //       <Grid container>
      //         <Grid item container xs={12} style={{ marginBottom: 25 }}>
      //           <Grid item xs={1}>
      //             <RedButton
      //               disabled={
      //                 rateExceptionData.fmvexceptioncommittestatus ===
      //                 "Approved By Committee Member"
      //                   ? true
      //                   : committeeApprovedDecision
      //               }
      //               id="view"
      //               label="Approved"
      //               onClick={this.handleCommitteeApprovedAction}
      //             />
      //           </Grid>
      //           <Grid item xs={1}>
      //             <RedButton
      //               disabled={
      //                 rateExceptionData.fmvexceptioncommittestatus ===
      //                 "Denied By Committee Member"
      //                   ? true
      //                   : committeeDeniedDecision
      //               }
      //               id="view"
      //               label="Denied"
      //               onClick={this.handleCommitteeDeniedAction}
      //             />
      //           </Grid>
      //           <Grid item xs={2}>
      //             <RedButton
      //               disabled={
      //                 rateExceptionData.fmvexceptioncommittestatus ===
      //                 "Committee Member Requested More Information"
      //                   ? true
      //                   : committeeMoreInfoDecision
      //               }
      //               id="view"
      //               label="Request More Information"
      //               onClick={this.handleCommitteeMoreInfoAction}
      //             />
      //           </Grid>
      //         </Grid>
      //         {rateExceptionData.fmvexceptioncommitteactiondate ? (
      //           <Grid item xs={12}>
      //             <Typography>
      //               Action :
      //               {`${moment(
      //                 rateExceptionData.fmvexceptioncommitteactiondate
      //               ).format("DD-MM-YYYY")}`}
      //             </Typography>
      //           </Grid>
      //         ) : (
      //           <></>
      //         )}
      //         {rateExceptionData.fmvexceptioncommittecomment ? (
      //           <>
      //             <Grid item xs={12}>
      //               <Label text="Comments:" />
      //             </Grid>
      //             <Grid item xs={12}>
      //               <TextInputField
      //                 id="fmvexceptioncommittecomment"
      //                 label=""
      //                 value={rateExceptionData.fmvexceptioncommittecomment}
      //                 onChange={onChange}
      //               />
      //             </Grid>
      //           </>
      //         ) : (
      //           <></>
      //         )}

      //         <ModalDialog
      //           isOpen={
      //             committeeApprovedDial
      //               ? committeeApprovedDial
      //               : committeeDeniedDial
      //               ? committeeDeniedDial
      //               : committeeMoreInfoDial
      //           }
      //           title="Comments"
      //           onClose={this.handleCommitteeDialClose}
      //           dialogWidth="md"
      //         >
      //           <Grid style={{ marginBottom: 15 }}>
      //             <TextInputField
      //               isRequired={false}
      //               id="fmvexceptioncommittecomment"
      //               label=""
      //               value={rateExceptionData.fmvexceptioncommittecomment}
      //               onChange={onChange}
      //             />
      //             {committeeMoreInfoError ? (
      //               <Typography
      //                 style={{
      //                   color: "#6d6e6e",
      //                 }}
      //               >
      //                 Comments are required in the event of requiring more
      //                 information.
      //               </Typography>
      //             ) : (
      //               <></>
      //             )}
      //             {committeeDeniedError ? (
      //               <Typography
      //                 style={{
      //                   color: "#6d6e6e",
      //                 }}
      //               >
      //                 Comments are required in case of denial.
      //               </Typography>
      //             ) : (
      //               <></>
      //             )}
      //           </Grid>
      //           <Grid item container justify="flex-end">
      //             <Grid style={{ marginRight: 8 }}>
      //               <RedButton
      //                 id="view"
      //                 label="Submit"
      //                 onClick={
      //                   committeeMoreInfoDial
      //                     ? this.handleCommitteeRequestMoreInfo
      //                     : committeeDeniedDial
      //                     ? this.handleCommitteeDenied
      //                     : this.handleCommitteeApproved
      //                 }
      //               />
      //             </Grid>
      //             <Grid>
      //               <WhiteButton
      //                 label="Cancel"
      //                 onClick={this.handleCommitteeDialClose}
      //               />
      //             </Grid>
      //           </Grid>
      //         </ModalDialog>
      //       </Grid>
      //     ) : (
      //       <></>
      //     )}
      //   </Box>
      // ) : (
      //   <></>
      // )}
    );
  }
  handleVPDialClose = () => {
    this.setState({
      vpMoreInfoDial: false,
      vpDeniedDial: false,
      vpMoreInfoError: false,
      vpDeniedError: false,
      vpApprovedDial: false,
    });
  };
  // handleCommitteeDialClose = () => {
  //   this.setState({
  //     committeeApprovedDial: false,
  //     committeeDeniedDial: false,
  //     committeeMoreInfoDial: false,
  //     committeeDeniedError: false,
  //     committeeMoreInfoError: false,
  //   });
  // };
  handleVPClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ viewVPDecision: !this.state.viewVPDecision });
  };
  // handleCommitteeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   this.setState({ viewCommitteeDecision: !this.state.viewCommitteeDecision });
  // };
  handleVPApprovedDail = () => {
    this.setState({ vpApprovedDial: true });
  };
  handleVPApproved = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const { rateExceptionData } = this.props;
    rateExceptionData.vpapproverstatus = "Approved By VP";
    rateExceptionData.vpapproveractiondate = new Date();
    await FMVRateExceptionService.update(
      rateExceptionData,
      rateExceptionData.requestid
    );
    this.setState({
      vpApprovedDecision: true,
      vpApprovedDial: false,
      vpDeniedDecision: false,
      vpMoreInfoDecision: false,
    });
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "VP",
        actiontype: rateExceptionData.vpapproverstatus,
        actiondate: new Date(),
        actiondetail: "Exception Approved By VP",
        comment: rateExceptionData.vpapprovercomment,
      };

      await FMVRateExceptionService.createAudit(exceptionAudit);
    }
    const emailObj: EmailDTO = {
      includeAdminInCC: true,
      to: rateExceptionData.requesteremail,
      cc: "",
      subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
      body: `<p>Hello,</p><p>FMV exception request has been approved by the Senior VP or above.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
    };
    await FMVRateExceptionService.sendEmail(emailObj);
    if (rateExceptionData.fmvadminteamstatus === "Reviewed By FMV Admin") {
      const emailObj: EmailDTO = {
        includeAdminInCC: true,
        to: this.state.toMailData,
        cc: `${this.state.ccMailData},${rateExceptionData.requesteremail}`,
        subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
        body: `<p>Hello,</p><p>An FMV exception request has been received by Global Transparency. This has been approved by the Senior VP or above in business unit/therapeutic area/budget holder.</p><p>Rate Exception Details is as follows:</p>
        <p>Function requesting exception: ${
          rateExceptionData.functionrequestingexception
        }</p>
        <p>Business unit requesting exception: ${
          rateExceptionData.businessunitrequestingexception
        }</p><p>Is there a marketed brand(s)/product(s) associated with the activity the exception is being sought for if applicable? ${
          rateExceptionData.associatedwithactivity
        }</p><p>VP Approver for this request: ${
          rateExceptionData.vpapprovername
        }</p>
        <p>HCP Name : ${rateExceptionData.firstname} ${
          rateExceptionData.lastname
        }</p><p>HCP Type: ${
          rateExceptionData.hcptype
        }</p><p>Lilly current rate: $${
          rateExceptionData.lillycurrentrate
        }</p><p>Lilly Current Group Tier: ${
          rateExceptionData.lillycurrentgrouptier
        }</p>
        <p>BI current rate: $${
          rateExceptionData.bicurrentrate
        }</p><p>BI Current Group Tier: ${
          rateExceptionData.bicurrentgrouptier
        }</p><p>Point to next tier: Lilly (${
          rateExceptionData.lillypointtonexttier
            ? rateExceptionData.lillypointtonexttier
            : "NA"
        }) BI (${
          rateExceptionData.bipointtonexttier
            ? rateExceptionData.bipointtonexttier
            : "NA"
        })</p><p>Requested FMV rate:  ${
          rateExceptionData.rateexceptionforlilly
            ? `Lilly $${rateExceptionData.rateexceptionforlilly}`
            : ``
        } ${
          rateExceptionData.rateexceptionforbi
            ? `BI $${rateExceptionData.rateexceptionforbi}`
            : ``
        }</p>
        <p>How was assigned FMV rate rejected by the external party?: ${
          rateExceptionData.howfmvraterejected
        }</p><p>Description of the business need/contracted activity requiring this specific HCP: ${
          rateExceptionData.describecontractactivityhcp
        }</p><p>Is the activity intended to include this HCP on the Thought Leader Engagement Plan?: ${
          rateExceptionData.ishcponthoughtleaderplan
        } ${
          rateExceptionData.ishcponthoughtleaderplancomment
            ? rateExceptionData.ishcponthoughtleaderplancomment
            : ``
        }</p>
        <p>Identify the skills/knowledge/expertise this HCP possesses which may not be captured in the PPQ and support the identified business need/contracted activity?: ${
          rateExceptionData.hcpexpertise
        }</p><p>Contract managed via 3rd party: ${
          rateExceptionData.isthirdpartycontract
        }</p><p>${
          rateExceptionData.fmvadminteamcomment
            ? `Additional Comments: ${rateExceptionData.fmvadminteamcomment}`
            : ``
        }</p><p>Please click here for the <a href=https://collab.lilly.com/sites/GTToolkit/SiteAssets/SitePages/FMV/US-FMV-Rates.pdf>FMV</a> rate tables.</p><p>Can you please revert directly to this email with your approval/rejection decision. A copy of all correspondence will be attached to the initial exception request.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
      };
      await FMVRateExceptionService.sendEmail(emailObj);
    }
    window.location.reload();
  };
  handleVPDeniedAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ vpDeniedDial: true });
  };
  handleVPDenied = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const { rateExceptionData } = this.props;

    if (rateExceptionData.vpapprovercomment) {
      rateExceptionData.vpapproverstatus = "Denied By VP";
      rateExceptionData.vpapproveractiondate = new Date();
      await FMVRateExceptionService.update(
        rateExceptionData,
        rateExceptionData.requestid
      );
      this.setState({
        vpDeniedDecision: true,
        vpDeniedError: false,
        vpDeniedDial: false,
        vpMoreInfoDecision: false,
        vpApprovedDecision: false,
      });
      if (rateExceptionData) {
        const exceptionAudit: RateExceptionAuditDTO = {
          requestid: rateExceptionData.requestid,
          facultyid: rateExceptionData.facultyid,
          actiontakenby: "VP",
          actiontype: rateExceptionData.vpapproverstatus,
          actiondate: new Date(),
          actiondetail: "Exception Denied By VP",
          comment: rateExceptionData.vpapprovercomment,
        };

        await FMVRateExceptionService.createAudit(exceptionAudit);
      }
      const emailObj: EmailDTO = {
        includeAdminInCC: true,
        to: rateExceptionData.requesteremail,
        cc: "",
        subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
        body: `<p>Hello,</p><p>FMV exception request has been denied by the VP approver.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${rateExceptionData.requestid}> here</a> where you can view the rationale for denial.</p><p>Kind regards,</p><p> Lilly FMV</p>`,
      };
      await FMVRateExceptionService.sendEmail(emailObj);
    } else {
      this.setState({ vpDeniedError: true });
    }
    //console.log(rateExceptionData);
    window.location.reload();
  };
  handleVPRequestMoreInfoAction = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    this.setState({ vpMoreInfoDial: true });
  };
  handleVPRequestMoreInfo = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { rateExceptionData } = this.props;
    if (rateExceptionData.vpapprovercomment) {
      rateExceptionData.vpapproverstatus = "VP Requested More Information";
      rateExceptionData.vpapproveractiondate = new Date();
      await FMVRateExceptionService.update(
        rateExceptionData,
        rateExceptionData.requestid
      );
      this.setState({
        vpMoreInfoDecision: true,
        vpMoreInfoError: false,
        vpMoreInfoDial: false,
        vpApprovedDecision: false,
        vpDeniedDecision: false,
      });
      if (rateExceptionData) {
        const exceptionAudit: RateExceptionAuditDTO = {
          requestid: rateExceptionData.requestid,
          facultyid: rateExceptionData.facultyid,
          actiontakenby: "VP",
          actiontype: rateExceptionData.vpapproverstatus,
          actiondate: new Date(),
          actiondetail: "VP Requested More Information",
          comment: rateExceptionData.vpapprovercomment,
        };

        await FMVRateExceptionService.createAudit(exceptionAudit);
      }
      const emailObj: EmailDTO = {
        includeAdminInCC: true,
        to: rateExceptionData.requesteremail,
        cc: "",
        subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
        body: `<p>Hello,</p><p>The VP approver has requested more information about your exception request.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvform/${rateExceptionData.requestid}> here</a> where you can respond with the additional requested information in the comment box.</p><p>Kind regards,</p><p> Lilly FMV</p>`,
      };
      await FMVRateExceptionService.sendEmail(emailObj);
    } else {
      this.setState({ vpMoreInfoError: true });
    }
    window.location.reload();
  };
  // handleCommitteeApprovedAction = () => {
  //   this.setState({ committeeApprovedDial: true });
  // };
  // handleCommitteeApproved = async (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   const { rateExceptionData } = this.props;
  //   rateExceptionData.fmvexceptioncommittestatus =
  //     "Approved By Committee Member";
  //   rateExceptionData.fmvexceptioncommitteactiondate = new Date();
  //   await FMVRateExceptionService.update(
  //     rateExceptionData,
  //     rateExceptionData.requestid
  //   );
  //   this.setState({
  //     committeeApprovedDecision: true,
  //     committeeApprovedDial: false,
  //     committeeDeniedDecision: false,
  //     committeeMoreInfoDecision: false,
  //   });
  //   if (rateExceptionData) {
  //     const exceptionAudit: RateExceptionAuditDTO = {
  //       requestid: rateExceptionData.requestid,
  //       facultyid: rateExceptionData.facultyid,
  //       actiontakenby: "Committee Member",
  //       actiontype: rateExceptionData.fmvexceptioncommittestatus,
  //       actiondate: new Date(),
  //       actiondetail: "Exception Approved By Committee Member",
  //       comment: rateExceptionData.fmvexceptioncommittecomment,
  //     };

  //     await FMVRateExceptionService.createAudit(exceptionAudit);
  //   }
  //   // const emailObj: EmailDTO = {
  //   //   includeAdminInCC: true,
  //   //   to: rateExceptionData.requesteremail,
  //   //   cc: this.state.toMailData,
  //   //   subject: "FMV Exception Request - Action Required",
  //   //   body: `<p>Hello,</p><p>FMV exception request has been approved by the Committee Member.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards</p><p> Lilly FMV</p>`,
  //   // };
  //   // await FMVRateExceptionService.sendEmail(emailObj);
  // };
  // handleCommitteeDeniedAction = () => {
  //   this.setState({ committeeDeniedDial: true });
  // };
  // handleCommitteeDenied = async (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   const { rateExceptionData } = this.props;
  //   if (rateExceptionData.fmvexceptioncommittecomment) {
  //     rateExceptionData.fmvexceptioncommittestatus =
  //       "Denied By Committee Member";
  //     rateExceptionData.fmvexceptioncommitteactiondate = new Date();
  //     await FMVRateExceptionService.update(
  //       rateExceptionData,
  //       rateExceptionData.requestid
  //     );
  //     this.setState({
  //       committeeDeniedDecision: true,
  //       committeeDeniedError: false,
  //       committeeDeniedDial: false,
  //       committeeApprovedDecision: false,
  //       committeeMoreInfoDecision: false,
  //     });
  //     if (rateExceptionData) {
  //       const exceptionAudit: RateExceptionAuditDTO = {
  //         requestid: rateExceptionData.requestid,
  //         facultyid: rateExceptionData.facultyid,
  //         actiontakenby: "Committee Member",
  //         actiontype: rateExceptionData.fmvexceptioncommittestatus,
  //         actiondate: new Date(),
  //         actiondetail: "Exception Denied By Committee Member",
  //         comment: rateExceptionData.fmvexceptioncommittecomment,
  //       };

  //       await FMVRateExceptionService.createAudit(exceptionAudit);
  //     }
  //     // const emailObj: EmailDTO = {
  //     //   includeAdminInCC: true,
  //     //   to: rateExceptionData.requesteremail,
  //     //   cc: "",
  //     //   subject: "FMV Exception Request - Action Required",
  //     //   body: `<p>Hello,</p><p>FMV exception request has been Denied by the Committee Member.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards</p><p> Lilly FMV</p>`,
  //     // };
  //     // await FMVRateExceptionService.sendEmail(emailObj);
  //   } else {
  //     this.setState({ committeeDeniedError: true });
  //   }
  // };
  // handleCommitteeMoreInfoAction = () => {
  //   this.setState({ committeeMoreInfoDial: true });
  // };
  // handleCommitteeRequestMoreInfo = async (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   const { rateExceptionData } = this.props;
  //   if (rateExceptionData.fmvexceptioncommittecomment) {
  //     rateExceptionData.fmvexceptioncommittestatus =
  //       "Committee Member Requested More Information";
  //     rateExceptionData.fmvexceptioncommitteactiondate = new Date();
  //     await FMVRateExceptionService.update(
  //       rateExceptionData,
  //       rateExceptionData.requestid
  //     );
  //     this.setState({
  //       committeeMoreInfoDecision: true,
  //       committeeMoreInfoError: false,
  //       committeeMoreInfoDial: false,
  //       committeeApprovedDecision: false,
  //       committeeDeniedDecision: false,
  //     });
  //     if (rateExceptionData) {
  //       const exceptionAudit: RateExceptionAuditDTO = {
  //         requestid: rateExceptionData.requestid,
  //         facultyid: rateExceptionData.facultyid,
  //         actiontakenby: "Committee Member",
  //         actiontype: rateExceptionData.fmvexceptioncommittestatus,
  //         actiondate: new Date(),
  //         actiondetail: "Committee Member Requested More Information",
  //         comment: rateExceptionData.fmvexceptioncommittecomment,
  //       };

  //       await FMVRateExceptionService.createAudit(exceptionAudit);
  //     }
  //     // const emailObj: EmailDTO = {
  //     //   includeAdminInCC: true,
  //     //   to: rateExceptionData.requesteremail,
  //     //   cc: "",
  //     //   subject: "FMV Exception Request - Action Required",
  //     //   body: `<p>Hello,</p><p>Committee Member requested more information for exception request.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards</p><p> Lilly FMV</p>`,
  //     // };
  //     // await FMVRateExceptionService.sendEmail(emailObj);
  //   } else {
  //     this.setState({ committeeMoreInfoError: true });
  //   }
  // };
}
export default VPAndCommitteDecision;
