import React from "react";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as EditIcon,
  faTrash as DeleteForeverIcon,
  faPlus as AddIcon,
} from "@fortawesome/free-solid-svg-icons";
import { CommitteeMemberManagementDTO } from "../../Models";
import LightTooltip from "../../Components/Tooltip/LightTooltip";

interface Props {
  id: string;
  title: string;
  titleElement?: JSX.Element | null;
  value: CommitteeMemberManagementDTO[];
  onAdd: () => void;
  onDelete: (Committee: CommitteeMemberManagementDTO) => void;
  onEdit: (Committee: CommitteeMemberManagementDTO) => void;
}

interface State {}

class SimpleCard extends React.Component<Props, State> {
  render() {
    const { title, titleElement, value } = this.props;
    return (
      <Card style={{ border: "none", boxShadow: "none" }}>
        <CardContent>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: "#5D5D5D",
                  marginLeft: 20,
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>{titleElement}</Grid>
            <Grid item>
              <LightTooltip title="Add">
                <IconButton onClick={this.onAddClick}>
                  <FontAwesomeIcon icon={AddIcon} size="xs" color="red" />
                </IconButton>
              </LightTooltip>
            </Grid>
          </Grid>
          <Grid>
            {value.map((d, i) => {
              return (
                <Grid
                  container
                  key={i}
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  style={{ marginLeft: 20 }}
                >
                  <Grid item>
                    <Typography>{d.firstname}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{d.middleinitials}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{d.lastname}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <a href={`mailto:${d.email}`}>{d.email}</a>
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                    <LightTooltip title="Edit">
                      <IconButton onClick={() => this.onEditClick(d)}>
                        <FontAwesomeIcon
                          icon={EditIcon}
                          size="xs"
                          color="red"
                        />
                      </IconButton>
                    </LightTooltip>

                    <LightTooltip title="Delete">
                      <IconButton onClick={() => this.onDeleteClick(d)}>
                        <FontAwesomeIcon
                          icon={DeleteForeverIcon}
                          size="xs"
                          color="red"
                        />
                      </IconButton>
                    </LightTooltip>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  onAddClick = () => {
    this.props.onAdd();
  };

  onDeleteClick = (Committee: CommitteeMemberManagementDTO) => {
    this.props.onDelete(Committee);
  };

  onEditClick = (Committee: CommitteeMemberManagementDTO) => {
    this.props.onEdit(Committee);
  };
}

export default SimpleCard;
