import React from "react";
import moment from "moment";
import { Divider, Grid, Typography } from "@material-ui/core";

import { FMVRateExceptionService } from "../../Services";
import { RateExceptionAuditDTO } from "../../Models";
import DetailCard from "../../Components/Card/DetailCard";

interface Props {
  requestid: string;
}
interface State {
  audits: RateExceptionAuditDTO[];
}
class ExceptionAuditDetails extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      audits: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { audits } = this.state;

    return (
      <DetailCard title="Exception Audit Details">
        <Grid container direction="row">
          {audits.map((audit, i) => {
            const actionDateTime = `${moment(audit.actiondate).format(
              "DD MMM YYYY"
            )} | ${moment(audit.actiondate).format("LT")}`;

            return (
              <Grid item md={12} key={`AUDIT-${i}`}>
                <Grid container direction="row" spacing={4}>
                  <Grid item md={12}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#5D5D5D",
                      }}
                    >
                      {actionDateTime}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Grid container justify="space-between" spacing={4}>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Action Type
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {audit.actiontype}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Action Taken by
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {audit.actiontakenby}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Action Details
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {audit.actiondetail}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                         Comment
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {audit.comment}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {audits.length - 1 !== i ? (
                  <Divider
                    variant="fullWidth"
                    style={{ marginTop: 15, marginBottom: 30 }}
                  />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </DetailCard>
    );
  }

  loadData = async () => {
    const { requestid } = this.props;

    const audits = await FMVRateExceptionService.getAllAudit(requestid);

    this.setState({ audits });
  };
}

export default ExceptionAuditDetails;
