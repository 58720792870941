import React from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import TabPanel from "../../Components/Tab/TabPanel";
import SelectModel from "../../Components/Select/SelectModel";
import { FacultyDTO, FacultyPPQDataDTO, FacultyQuestDTO, PPQResponseReportModel, RateExceptionReportModel } from "../../Models";

import { TabModel } from "./TabModel";
import PPQSummaryReportTable from "./PPQSummaryReport/PPQSummaryReportTable";
import PPQSummaryReportFilter from "./PPQSummaryReport/PPQSummaryReportFilter";
import PPQSummaryReportFilterModel from "./PPQSummaryReport/PPQSummaryReportFilterModel";
import ExceptionRateReportTable from "./ExceptionRateReport/ExceptionRateReportTable";
import ExceptionRateReportFilter from "./ExceptionRateReport/ExceptionRateReportFilter";
import ExceptionRateReportFilterModel from "./ExceptionRateReport/ExceptionRateReportFilterModel";
import PayerReportTable from "./PayerReport/PayerReportTable";
import PayerReportFilter from "./PayerReport/PayerReportFilter";
import PayerReportFilterModel from "./PayerReport/PayerReportFilterModel";
import SubmissionDateReportTable from "./SubmissionDateReport/SubmissionDateReportTable";
import SubmissionDateReportFilter from "./SubmissionDateReport/SubmissionDateReportFilter";
import SubmissionDateReportFilterModel from "./SubmissionDateReport/SubmissionDateReportFilterModel";
import CredentialReportTable from "./CredentialReport/CredentialReportTable";
import CredentialReportFilter from "./CredentialReport/CredentialReportFilter";
import CredentialReportFilterModel from "./CredentialReport/CredentialReportFilterModel";
import PPQResponseReportTable from "./PPQResponseReport/PPQResponseReportTable";
import PPQResponseReportFilter from "./PPQResponseReport/PPQResponseReportFilter";
import PPQResponseReportFilterModel from "./PPQResponseReport/PPQResponseReportFilterModel";
import PendingTieringReportFilterModel from "./PendingTieringReport/PendingTieringReportFilterModel";
import PendingTieringReportTable from "./PendingTieringReport/PendingTieringReportTable";
import PendingTieringReportFilter from "./PendingTieringReport/PendingTieringReportFilter";
import { Pagination } from "@material-ui/lab";


const TabLabelComponent: React.FC<{ tab: TabModel, isSelected: boolean }> = ({ tab, isSelected }) => {
    return (
        <Grid container alignItems="center" justify="center">
            <Grid item>
                <Typography style={{ fontSize: 16, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#383838', padding: 5 }}>
                    {tab.name}
                </Typography>
            </Grid>
        </Grid>
    );
};

interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    onTabChange: ((selectedTab: TabModel) => void);
    hcptypes: SelectModel[];
    specialties: SelectModel[];
    credentials: SelectModel[];
    ppqSummaryReportData: FacultyPPQDataDTO[];
    ppqSummaryReportFilter: PPQSummaryReportFilterModel;
    onPPQSummaryFilterChange: ((ppqSummaryReportFilter: PPQSummaryReportFilterModel) => void);
    exceptionRateReportData: RateExceptionReportModel;
    exceptionRateReportFilter: ExceptionRateReportFilterModel;
    onExceptionRateFilterChange: ((exceptionRateReportFilter: ExceptionRateReportFilterModel) => void);
    payerReportData: FacultyPPQDataDTO[];
    payerReportFilter: PayerReportFilterModel;
    onPayerFilterChange: ((payerReportFilter: PayerReportFilterModel) => void);
    submissionDateReportData: FacultyPPQDataDTO[];
    submissionDateReportFilter: SubmissionDateReportFilterModel;
    onSubmissionDateFilterChange: ((submissionDateReportFilter: SubmissionDateReportFilterModel) => void);
    credentialReportData: FacultyPPQDataDTO[];
    credentialReportFilter: CredentialReportFilterModel;
    onCredentialFilterChange: ((credentialReportFilter: CredentialReportFilterModel) => void);
    ppqResponseReportData: PPQResponseReportModel[];
    ppqResponseReportFilter: PPQResponseReportFilterModel;
    onPPQResponseFilterChange: ((ppqResponseReportFilter: PPQResponseReportFilterModel) => void);
    pendingTieringReportData: FacultyDTO[];
    pendingTieringReportFilter: PendingTieringReportFilterModel;
    onPendingTieringFilterChange: ((pendingTieringReportFilter: PendingTieringReportFilterModel) => void);
    signOfAnswers: FacultyQuestDTO[];
    page: number;
    pageSize: number;
    totalCount: number;
    onPageChange: ((event: React.ChangeEvent<unknown>, value: number) => void);
    onExport: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}
interface State { }
class TabBar extends React.Component<Props, State> {
    render() {
        const {
            tabs, currentTab,
            page, pageSize, totalCount, onPageChange,
            hcptypes, specialties, credentials,
            ppqSummaryReportData, ppqSummaryReportFilter, onPPQSummaryFilterChange,
            exceptionRateReportData, exceptionRateReportFilter, onExceptionRateFilterChange,
            payerReportData, payerReportFilter, onPayerFilterChange,
            submissionDateReportData, submissionDateReportFilter, onSubmissionDateFilterChange,
            credentialReportData, credentialReportFilter, onCredentialFilterChange,
            ppqResponseReportData, ppqResponseReportFilter, onPPQResponseFilterChange,
            pendingTieringReportData, pendingTieringReportFilter, onPendingTieringFilterChange,
            signOfAnswers, onExport,
        } = this.props;

        return (
            <Box mt={2}>
                <Tabs
                    value={currentTab.index}
                    onChange={this.handleTabChange}
                    aria-label="Report Tabs"
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: 'none',
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {tabs.map((tab, i) => {
                        const tabProps = {
                            id: `full-width-tab-${i}`,
                            'aria-controls': `full-width-tabpanel-${i}`,
                        };

                        const isSelected = currentTab.index === i;

                        const tabStyle: React.CSSProperties = {
                            backgroundColor: '#F4F4F4',
                            textTransform: 'none',
                            marginRight: 10,
                            border: '1px solid #D5D5D5',
                            borderRadius: '6px 6px 0px 0px',
                        };

                        if (isSelected) {
                            tabStyle.backgroundColor = '#EE3A29';
                            tabStyle.border = 'none';
                        }

                        return (
                            <Tab {...tabProps} key={tabProps.id}
                                label={
                                    <TabLabelComponent
                                        tab={tab}
                                        isSelected={isSelected}
                                    />
                                }
                                style={tabStyle} />
                        );
                    })}
                </Tabs>
                <TabPanel value={currentTab.index} index={0}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <PPQSummaryReportFilter ppqSummaryReportData={ppqSummaryReportData}
                                hcptypes={hcptypes} specialties={specialties}
                                ppqSummaryReportFilter={ppqSummaryReportFilter} onFilterChange={onPPQSummaryFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <PPQSummaryReportTable ppqSummaryReportData={ppqSummaryReportData} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <ExceptionRateReportFilter exceptionRateReportData={exceptionRateReportData}
                                exceptionRateReportFilter={exceptionRateReportFilter} onFilterChange={onExceptionRateFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <ExceptionRateReportTable exceptionRateReportData={exceptionRateReportData} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={2}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <PayerReportFilter payerReportData={payerReportData}
                                payerReportFilter={payerReportFilter} onFilterChange={onPayerFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <PayerReportTable payerReportData={payerReportData} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={3}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <SubmissionDateReportFilter submissionDateReportData={submissionDateReportData}
                                submissionDateReportFilter={submissionDateReportFilter} onFilterChange={onSubmissionDateFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <SubmissionDateReportTable submissionDateReportData={submissionDateReportData} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={4}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <CredentialReportFilter
                                hcptypes={hcptypes} credentials={credentials} credentialReportData={credentialReportData}
                                credentialReportFilter={credentialReportFilter} onFilterChange={onCredentialFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <CredentialReportTable credentialReportData={credentialReportData} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={5}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <PPQResponseReportFilter hcptypes={hcptypes} ppqResponseReportFilter={ppqResponseReportFilter}
                                onFilterChange={onPPQResponseFilterChange} onExport={onExport} />
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item md={12}>
                                <PPQResponseReportTable ppqResponseReportData={ppqResponseReportData} />
                            </Grid>
                            <Grid container item md={12} justify="flex-end">
                                <Pagination size="medium"
                                    count={Math.ceil(totalCount / pageSize)} page={page} onChange={onPageChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={6}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <PendingTieringReportFilter pendingTieringReportData={pendingTieringReportData} signOfAnswers={signOfAnswers}
                                pendingTieringReportFilter={pendingTieringReportFilter} onFilterChange={onPendingTieringFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <PendingTieringReportTable pendingTieringReportData={pendingTieringReportData} signOfAnswers={signOfAnswers} />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;
        const selectedTab = tabs.find(p => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;