import React from 'react';

import { Box, Grid, Typography, Divider } from '@material-ui/core';
import TextInputField from "../../../../Components/TextInput/TextInputField";
import RedButton from '../../../../Components/Button/RedButton';
import WhiteButton from '../../../../Components/Button/WhiteButton';
import PageHeader from '../../../../Components/Text/PageHeader';

import { CommitteeMemberManagementDTO } from "../../../../Models";




interface Props { 
  CommitteeMember: CommitteeMemberManagementDTO;
  onCancelClick: (() => void);
  onSubmit: ((committeemember: CommitteeMemberManagementDTO) => void);
}
interface State {
  isFormVisible: boolean;
  committeemember: CommitteeMemberManagementDTO
  formValidationState: { isValid: boolean }[];
}

const defaultProps = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  padding: 3,

};

class CommitteeMemberManagementForm extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    let isValid = false;

    if (props.CommitteeMember && props.CommitteeMember.id) {
      isValid = true;
  }

    this.state = {
      isFormVisible: true,
      committeemember: props.CommitteeMember,
      formValidationState: [
        { isValid },
    ],
    }
  }
  render() {
    const { committeemember, formValidationState } = this.state;
    const isFormNotValid = formValidationState.some(p => p.isValid === false);

    return (
      <>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 30 }} />
      <Box borderColor="grey.500" borderRadius="borderRadius" {...defaultProps} >
        <PageHeader label={committeemember.id !== undefined? 'Update Committee Member' :  'Add Committee Member'}/>
        <Grid container spacing={2} style={{marginTop: 30, marginLeft: 20}}>
          <Grid item xs={3} sm={2}>
            <Typography
              style={{
                fontWeight: 520,
                color: "#000000d6",
                marginRight: 30,
                marginTop: 15,
              }}
            >
              FacultyId :
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} style={{marginLeft: -80, marginRight: 60}}>
            <TextInputField
              // width="300px"
              // name="cpsfacultykey"
              isRequired= {false}
              id="facultyid"
              label="FacultyId"
              value={committeemember.facultyid}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3} sm={2} style={{marginLeft: 40}}>
            <Typography
              style={{
                fontWeight: 520,
                color: "#000000d6",
                marginRight: 30,
                marginTop: 15,
              }}
            >
              FirstName* :
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} style={{marginLeft: -80, marginRight: 60}}>
            <TextInputField
              // width="300px"
              // name="firstname"
              id="firstname"
              label="FirstName"
              value={committeemember.firstname}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <Typography 
              style={{
                fontWeight: 520,
                color: "#000000d6",
                marginRight: 30,
                marginTop: 15,
              }}
            >
              MiddleName :
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} style={{marginLeft: -80, marginRight: 60}}>
            <TextInputField
              // width="300px"
              // name="lastname"
              isRequired= {false}
              id="middleinitials"
              label="MiddleName"
              value={committeemember.middleinitials}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3} sm={2} style={{marginLeft: 40}}>
            <Typography
              style={{
                fontWeight: 520,
                color: "#000000d6",
                marginRight: 30,
                marginTop: 15,
              }}
            >
              LastName* :
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} style={{marginLeft: -80, marginRight: 60}}>
            <TextInputField
              // width="300px"
              // name="lastname"
              id="lastname"
              label="LastName"
              value={committeemember.lastname}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 10, marginLeft: 20 }}>
          <Grid item xs={3} sm={2}>
            <Typography
              style={{
                fontWeight: 520,
                color: "#000000d6",
                marginRight: 30,
                marginTop: 15,
              }}
            >
              Email ID* :
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} style={{marginLeft: -80, marginRight: 60}}>
            <TextInputField
              // width="300px"
              // name="customerid"
              id="email"
              label=" Email Id"
              value={committeemember.email}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid alignItems="flex-end" justify='flex-end' style={{ marginTop: 40, marginLeft: 25 }}>
          <WhiteButton label="Cancel" onClick={this.props.onCancelClick} style={{ marginRight: 20 }} />
          {/* <RedButton label='Add' onClick={this.handleSubmit} /> */}
          {isFormNotValid ? 
                <RedButton disabled={false} label={committeemember.id !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} /> :
                <RedButton label={committeemember.id !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} />
            }
        </Grid>
      </Box>
      </>
    )
  }

  handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
) => {
    if (event) {
        this.setState((prevState) => {
            let committeemember: any = Object.assign({}, prevState.committeemember);
            committeemember[event.target.id] = event.target.value;
            return { committeemember };
        });
    }
};

  handleSubmit = () => {
    const { committeemember } = this.state;
    this.props.onSubmit(committeemember);
  }


  handleCancelClick = () => {
    this.setState({ isFormVisible: false });
  }
}

export default CommitteeMemberManagementForm;
