import React from "react";

import { CustomEventType } from "../../Common/Enums";
import {
  FMVRateExceptionService,
  CustomEventHandlerService,
} from "../../Services";
import { ExceptionManagementDTO } from "../../Models";
import MyRequestTable from "./MyRequestTable";
import Alert from "../../Components/Alert/Alert";
import { Grid } from "@material-ui/core";

interface Props {}
interface State {
  requestedData: ExceptionManagementDTO[];
}
class MyRequest extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      requestedData: [],
    };
  }

  async componentDidMount() {
    const path: any = window.location.pathname;
    const requesterEmail = path.split("/")[2];

    const requestedData = await FMVRateExceptionService.getByRequesterEmail(
      requesterEmail
    );
    //console.log('requestedData', requestedData);
    this.setState({ requestedData });
    if (requestedData) {
      CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }
    const id = localStorage.getItem("Id");

    if (id === "created") {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "created",
        message: "Request has been created successfully.",
      });
      localStorage.removeItem("Id");
    }
    if (id === "updated") {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "updated",
        message: "Request has been updated successfully.",
      });
      localStorage.removeItem("Id");
    }
    if (id === "saved") {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "saved",
        message: "Request has been saved successfully.",
      });
      localStorage.removeItem("Id");
    }
  }

  render() {
    const { requestedData } = this.state;
    return (
      <>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <Alert id="updated" />
          <Alert id="created" />
          <Alert id="saved" />
        </Grid>
        <MyRequestTable requestData={requestedData} />
      </>
    );
  }
}

export default MyRequest;
