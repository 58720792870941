import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { FacultyPPQDataDTO } from "../../../Models";
import TextInputField from '../../../Components/TextInput/TextInputField';
import RedButton from '../../../Components/Button/RedButton';
import WhiteButton from '../../../Components/Button/WhiteButton';
import SelectModel from '../../../Components/Select/SelectModel';
import RateUpdateExceptionService from "../../../Services/FacultyPPQDataService";
import SingleAutoComplete from '../../../Components/Select/SingleAutoComplete';
import { ValidationType } from '../../../Common/Enums';


const rationaleOptions = [
    { value: "FMV Exception Rate Approved", text: "FMV Exception Rate Approved" },
    { value: "FMV Exception Zero Dollar Contract", text: "FMV Exception Zero Dollar Contract" },
    { value: "FMV Exception Restricted Rate", text: "FMV Exception Restricted Rate" },
    { value: "FMV Exception Previous Rate Honoured", text: "FMV Exception Previous Rate Honoured" },
    { value: "Other", text: "Other" },
];

interface Props {
    rateException?: FacultyPPQDataDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((rateException: FacultyPPQDataDTO) => void);
}
interface State {
    rateException: FacultyPPQDataDTO;
    hcpFirstLastNames: SelectModel[];
    hcpFacultyId: SelectModel[];
    formValidationState: { name: string, isValid: boolean }[];
    rationale: string;
    otherrationale: string;
}
class RateExceptionForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;
        let rateException = {} as FacultyPPQDataDTO;
        let rationale = '';
        let otherrationale = '';

        if (props.rateException && props.rateException.id) {
            isValid = true;
            rateException = props.rateException;

            let isExists = rationaleOptions.filter(p => !(p.value === 'Other')).find(p => p.value === rateException.rateexceptionrationale);

            if (isExists) {
                rationale = rateException.rateexceptionrationale;
            } else {
                rationale = 'Other';
                otherrationale = rateException.rateexceptionrationale;
            }
        }

        this.state = {
            rateException: rateException,
            hcpFirstLastNames: [],
            hcpFacultyId: [],
            formValidationState: [
                { name: 'firstname', isValid: true },
                { name: 'lastname', isValid: true },
                { name: 'facultyid', isValid: true },
                { name: 'lillyrate', isValid: true },
                { name: 'lillyexceptionrate', isValid },
                { name: 'birate', isValid: true },
                { name: 'biexceptionrate', isValid },
                { name: 'otherrationale', isValid: true },
            ],
            rationale,
            otherrationale,
        };
    }

    async componentDidMount() {
        const hcpFirstLastNames = await RateUpdateExceptionService.getAllHCPByFirstLastName();
        const hcpFacultyId = await RateUpdateExceptionService.getAllHCPByFacultyId();

        this.setState({ hcpFirstLastNames, hcpFacultyId });
    }

    render() {
        const { rateException, hcpFirstLastNames, hcpFacultyId, rationale, otherrationale, formValidationState } = this.state;
        const { onCancelClick } = this.props;
        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={4}
                            alignItems="center"
                            justify="flex-start"
                        >
                            <Grid item xs={3}>
                                <SingleAutoComplete isRequired={false} id="firstlastname" label="First & Last Name"
                                    selectedValue={`${rateException.firstname} ${rateException.lastname}`} values={hcpFirstLastNames} onChange={this.handleFirstLastNameSelectSearch} />
                            </Grid>
                            <Grid item xs={3}>
                                <SingleAutoComplete isRequired={false} id="facultyid" label="Faculty ID"
                                    selectedValue={rateException.facultyid} values={hcpFacultyId} onChange={this.handleFacultyIdSelectSearch} />
                            </Grid>
                        </Grid>
                        <Grid
                            container spacing={4}
                            alignItems="center"
                            justify="flex-start"
                        >
                            <Grid item>
                                <TextInputField isDisabled id="lillyrate" label="Lilly Old Rate"
                                    value={rateException.lillyrate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextInputField id="lillyexceptionrate" label="Lilly New Rate"
                                    inputType="number" validationType={ValidationType.RATE}
                                    value={rateException.lillyexceptionrate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextInputField isDisabled id="birate" label="Alliance Old Rate"
                                    value={rateException.birate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextInputField id="biexceptionrate" label="Alliance New Rate"
                                    inputType="number" validationType={ValidationType.RATE}
                                    value={rateException.biexceptionrate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <SingleAutoComplete isRequired={false} id="rationale" label="Rationale"
                                    selectedValue={rationale} values={rationaleOptions} onChange={this.handleRationaleChange} />
                            </Grid>
                            {rationale === 'Other' ?
                                <Grid item>
                                    <TextInputField id="otherrationale" label="Other" value={otherrationale} onChange={this.handleOtherRationaleChange} />
                                </Grid> : <></>}
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                {isFormNotValid ?
                                    <RedButton disabled={true} label={rateException.id !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} /> :
                                    <RedButton label={rateException.id !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} />}
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let rateException: any = Object.assign({}, prevState.rateException);
                rateException[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { rateException };
            });
        }
    }

    handleFirstLastNameSelectSearch = async (selected: SelectModel, targetId?: string, isValid?: boolean) => {
        if (selected.value) {
            const { rateException } = this.state;
            const selectedValue = selected.value as string;
            const values = selectedValue.split(' ');

            rateException.firstname = values[0];
            rateException.lastname = values[1];
            rateException.facultyid = -1;

            const rateupdateexceptionsearched = await RateUpdateExceptionService.getFacultyPPQDataByField(rateException);
            if (rateupdateexceptionsearched) {
                this.setState(prevState => {
                    let formValidationState = prevState.formValidationState;
                    const lillyExceptionIndex = formValidationState.findIndex(p => p.name === 'lillyexceptionrate');
                    formValidationState[lillyExceptionIndex] = { name: 'lillyexceptionrate', isValid: true };
                    const biExceptionIndex = formValidationState.findIndex(p => p.name === 'biexceptionrate');
                    formValidationState[biExceptionIndex] = { name: 'biexceptionrate', isValid: true };

                    rateupdateexceptionsearched.lillyexceptionrate = rateupdateexceptionsearched.lillyrate;
                    rateupdateexceptionsearched.biexceptionrate = rateupdateexceptionsearched.birate;
                    return { rateException: rateupdateexceptionsearched, formValidationState };
                });
            }
        }
    }

    handleFacultyIdSelectSearch = async (selected: SelectModel, targetId?: string, isValid?: boolean) => {
        if (selected.value) {
            const { rateException } = this.state;

            rateException.firstname = '?';
            rateException.lastname = '?';
            rateException.facultyid = selected.value as number;

            const rateupdateexceptionsearched = await RateUpdateExceptionService.getFacultyPPQDataByField(rateException);
            if (rateupdateexceptionsearched) {
                this.setState(prevState => {
                    let formValidationState = prevState.formValidationState;
                    const index = formValidationState.findIndex(p => p.name === 'facultyid');
                    formValidationState[index] = { name: 'facultyid', isValid: isValid ? isValid : false };
                    const lillyExceptionIndex = formValidationState.findIndex(p => p.name === 'lillyexceptionrate');
                    formValidationState[lillyExceptionIndex] = { name: 'lillyexceptionrate', isValid: true };
                    const biExceptionIndex = formValidationState.findIndex(p => p.name === 'biexceptionrate');
                    formValidationState[biExceptionIndex] = { name: 'biexceptionrate', isValid: true };

                    rateupdateexceptionsearched.lillyexceptionrate = rateupdateexceptionsearched.lillyrate;
                    rateupdateexceptionsearched.biexceptionrate = rateupdateexceptionsearched.birate;
                    return { rateException: rateupdateexceptionsearched, formValidationState };
                });
            }
        }
    }

    handleRationaleChange = (selected: SelectModel, targetId?: string, isValid?: boolean) => {
        if (targetId) {
            this.setState(prevState => {
                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === 'otherrationale');

                if (selected.value === 'Other') {
                    formValidationState[index] = { name: 'otherrationale', isValid: false };
                } else {
                    formValidationState[index] = { name: 'otherrationale', isValid: true };
                }

                return { rationale: selected.value.toString(), otherrationale: '', formValidationState };
            });
        }
    }

    handleOtherRationaleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === 'otherrationale');
                formValidationState[index] = { name: 'otherrationale', isValid };

                return { otherrationale: event.target.value, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { rateException, rationale, otherrationale } = this.state;

        if (rationale) {
            if (rationale === 'Other') {
                rateException.rateexceptionrationale = otherrationale;
            } else {
                rateException.rateexceptionrationale = rationale;
            }
        }

        this.props.onSubmit(rateException);
    }
}

export default RateExceptionForm;

