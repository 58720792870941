import React from "react";
import { Box } from "@material-ui/core";

import { CustomEventType, EventType, DocumentType, QuestionnaryStatus } from "../../../Common/Enums";

import { FacultyDTO, QuestionnaireDTO, FacultyQuestDTO, FacultyPPQDataDTO } from "../../../Models";
import { ADMINFileService, ADMINQuestionnaireService, CustomEventHandlerService } from "../../../Services";

import DetailCard from "../../../Components/Card/DetailCard";
import QuestionnaireForm from "../../../Components/Questionnaire/QuestionnaireForm";
import IconButton from "../../../Components/Button/IconButton";
import Alert from "../../../Components/Alert/Alert";

import QuestionnaireReadOnlyForm from "./QuestionnaireReadOnlyForm";
import { TabModel } from "../TabModel";
import QuestionnaireUtils from "../../../Common/QuestionnaireUtils";

interface Props {
    currentTab: TabModel;
    selectedFaculty?: FacultyDTO;
    facultyPPQData: FacultyPPQDataDTO | null;
    onSubmitSuccess: ((selectedFaculty: FacultyDTO, message: string) => void);
    onQuestionnaireChange: ((questionnaire: QuestionnaireDTO) => void);
}
interface State {
    questionnaire?: QuestionnaireDTO;
    isEdit: boolean;
}
class ProfessionalProfileQuestionnaire extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            questionnaire: undefined,
            isEdit: false,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedFaculty) {
            if (this.props.selectedFaculty.facultyid !== (prevProps.selectedFaculty && prevProps.selectedFaculty.facultyid)) {
                this.loadData();
            }
        } else {
            const { questionnaire } = this.state;

            if (questionnaire) {
                this.setState({
                    questionnaire: undefined,
                    isEdit: false,
                });
            }
        }
    }

    render() {
        const { questionnaire, isEdit } = this.state;

        if (questionnaire) {
            return (
                <DetailCard title="Professional Profile Questionnaire"
                    titleElement={!isEdit ? <IconButton iconType="Edit" onClick={this.handleEditClick} /> : null}>
                    <>
                        <Box p={2} style={{ marginBottom: 10 }} data-html2canvas-ignore>
                            <Alert />
                        </Box>
                        {isEdit ?
                            <QuestionnaireForm questionnaire={questionnaire}
                                onSave={this.handleSaveAnswer} onSubmit={this.handleSubmitAnswer} onCancel={this.handleCancelClick} /> :
                            <QuestionnaireReadOnlyForm questionnaire={questionnaire} />}
                    </>
                </DetailCard>
            );
        }

        return null;
    }

    loadData = async () => {
        const { currentTab, selectedFaculty, facultyPPQData, onQuestionnaireChange } = this.props;

        if (selectedFaculty) {
            const { facultyid } = selectedFaculty;
            const isTiered = currentTab.type === 'Tiered';

            const isException = facultyPPQData?.ppqsubmissionstatus === QuestionnaryStatus.EXCEPTION;

            const questionnaire = await ADMINQuestionnaireService.getAll(facultyid, isTiered, isException);

            if (questionnaire) {
                questionnaire.facultyId = facultyid;
                questionnaire.ppqyear = new Date().getFullYear();

                this.setState({ questionnaire, isEdit: false }, () => {
                    if (currentTab.type === 'InitiatePPQ') {
                        this.handleEditClick();
                    }
                    onQuestionnaireChange(questionnaire);
                });
            }
        }
    }

    handleEditClick = () => {
        this.setState({ isEdit: true });
    }

    handleCancelClick = () => {
        this.setState({ isEdit: false });
    }

    handleSaveAnswer = async (answer: FacultyQuestDTO, eventType: EventType) => {
        const { questionnaire } = this.state;
        const { currentTab } = this.props;
        const isTiered = currentTab.type === 'Tiered';
        const isInitiatePPQ = currentTab.type === 'InitiatePPQ';

        if (questionnaire) {
            const updatedQuestionnaire = await ADMINQuestionnaireService.handleSaveAnswer(questionnaire, answer, eventType, isTiered, isInitiatePPQ);
            this.setState({ questionnaire: updatedQuestionnaire });
        }
    }

    handleSubmitAnswer = async () => {
        const { questionnaire } = this.state;
        const { currentTab, facultyPPQData } = this.props;
        const isTiered = currentTab.type === 'Tiered';
        const isInitiatePPQ = currentTab.type === 'InitiatePPQ';

        if (questionnaire) {
            const isQuestionnaireValid = QuestionnaireUtils.validateQuestionnaire(questionnaire);

            if (isQuestionnaireValid) {
                const facultyFiles = await ADMINFileService.getAll(questionnaire.facultyId, DocumentType.CV);
                const cvFiles = facultyFiles.filter(file => file.documenttype === DocumentType.CV).sort((a, b) => {
                    if (a.createdon && b.createdon) {
                        return new Date(b.createdon).valueOf() - new Date(a.createdon).valueOf();
                    }

                    return -1;
                });

                if (cvFiles.length > 0) {
                    const cvFile = cvFiles[0];
                    const fileMetaData = await ADMINFileService.getMetaData(cvFile.s3filekey);

                    if (fileMetaData === null) {
                        CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, "There is an error while uploading CV. Please try again by deleting and uploading CV with correct size and format.");
                    } else {
                        const response = await ADMINQuestionnaireService.handleSubmitAnswer(questionnaire, isInitiatePPQ);

                        if (response.isSuccess) {
                            const isException = facultyPPQData?.ppqsubmissionstatus === QuestionnaryStatus.EXCEPTION;
                            const updatedQuestionnaire = await ADMINQuestionnaireService.getAll(questionnaire.facultyId, isTiered, isException);

                            this.setState({ questionnaire: updatedQuestionnaire, isEdit: false }, () => {
                                const { selectedFaculty } = this.props;

                                if (selectedFaculty) {
                                    this.props.onSubmitSuccess(selectedFaculty, response.message);
                                }
                            });
                        }
                    }
                } else {
                    CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, "CV is mandatory. Please upload CV before submitting.");
                }
            } else {
                CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, "All questions are mandatory. Please answer all questions before submitting.");
            }
        }
    }
}

export default ProfessionalProfileQuestionnaire;
