import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';
import ContentfulService from "./ContentfulService";
import ADMINFileService from "./ADMINFileService";

import { QuestionnaireDTO, FacultyQuestDTO, TabFacultyCountDTO, ContentfulAnswer } from "../Models";
import { EventType, FieldType, SortType, CustomEventType, UserRole } from "../Common/Enums";
import QuestionnaireUtils from "../Common/QuestionnaireUtils";
import FacultyPPQDataService from "./FacultyPPQDataService";

class ADMINQuestionnaireService extends ProQuestAPIService {
    async getAll(facultyId: number, isSubmitted: boolean, isException: boolean): Promise<QuestionnaireDTO | undefined> {
        const questionnaire: QuestionnaireDTO = {
            facultyId,
            questionnaire: [],
            facultyQuests: [],
        };

        if (isException) {
            isSubmitted = true;
        }

        const getAnswersURL = isSubmitted ? `adminquestionnaire/getsubmittedanswers/${facultyId}` :
            `adminquestionnaire/getsavedanswers/${facultyId}`;

        const contentfulQuestions = await ContentfulService.getQuestions();
        const contentfulAnswers = await ContentfulService.getAnswers();
        const response = await this.GetAsync<FacultyQuestDTO[]>(getAnswersURL);

        if (response.isSuccess) {
            questionnaire.facultyQuests = response.data;
        }

        contentfulQuestions.forEach((question) => {
            const questionAnswers = contentfulAnswers.filter(answer => answer.qid === question.id).sort((a, b) =>
                a.id - b.id
            );

            let activeQuestionAnswers: ContentfulAnswer[] = [];
            questionAnswers.forEach((qa) => {
                if (qa.isActive) {
                    activeQuestionAnswers.push(qa);
                } else {
                    const facultyQuest = questionnaire.facultyQuests.find(p => p.answerid === qa.id);

                    if (facultyQuest) {
                        activeQuestionAnswers.push(qa);
                    }
                }
            });

            switch (question.answerSort) {
                case SortType.ALPHA:
                    activeQuestionAnswers = activeQuestionAnswers.sort((a, b) => {
                        return a.answerOptions.localeCompare(b.answerOptions);
                    });
                    break;
                default:
                    break;
            }

            question.questionAnswers = activeQuestionAnswers;
        });

        const questions = contentfulQuestions.filter(q => {
            if (q.config) {
                if (q.config.showToRole) {
                    if (q.config.showToRole === UserRole[UserRole.LILLY_ADMIN]) {
                        return true;
                    }

                    return false;
                }
            }

            return true;
        });

        questionnaire.questionnaire = questions.sort((a, b) => {
            if (a.number === b.number) {
                return a.numberText.localeCompare(b.numberText);
            }

            return a.number - b.number;
        });
console.log('questionnaire',questionnaire);

        return questionnaire;
    }

    async getTabFacultyCount(): Promise<TabFacultyCountDTO | undefined> {
        const response = await this.GetAsync<TabFacultyCountDTO>('adminquestionnaire/tabsfacultycount');

        if (response.isSuccess) {
            return response.data;
        }

        return undefined;
    }

    async handleSaveAnswer(questionnaire: QuestionnaireDTO, answer: FacultyQuestDTO, eventType: EventType, isTiered: boolean, ppqInitiation?: boolean): Promise<QuestionnaireDTO> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        let isDeleteSuccess: boolean | null = null;
        answer.facultyid = questionnaire.facultyId;
        answer.ppqyear = questionnaire.ppqyear ?? new Date().getFullYear();

        const question = questionnaire.questionnaire.find(p => p.id === answer.questionno);
        let existsAnswer: FacultyQuestDTO | undefined = undefined;

        if (eventType === EventType.ADD) {
            switch (question?.responseType) {
                case FieldType[FieldType.MultiDropdown]:
                case FieldType[FieldType.MultiCheckbox]:
                    existsAnswer = questionnaire.facultyQuests.find(p =>
                        p.questionno === answer.questionno && p.answer === answer.answer);

                    break;
                default:
                    existsAnswer = questionnaire.facultyQuests.find(p =>
                        p.questionno === answer.questionno);

                    break;
            }
        }
        else if (eventType === EventType.REMOVE) {
            existsAnswer = questionnaire.facultyQuests.find(p =>
                p.questionno === answer.questionno && p.answer === answer.answer);
        }

        if (existsAnswer) {
            isDeleteSuccess = await this.delete(existsAnswer, isTiered);

            if (isDeleteSuccess) {
                questionnaire.facultyQuests.splice(questionnaire.facultyQuests.indexOf(existsAnswer), 1);
            }
        }

        if (eventType === EventType.ADD) {
            if (isDeleteSuccess === null || isDeleteSuccess === true) {
                const createdAnswer = await this.create(answer, isTiered, ppqInitiation);

                if (createdAnswer) {
                    answer = createdAnswer;
                    questionnaire.facultyQuests.push(answer);
                }
            }
        }

        if (isDeleteSuccess === null || isDeleteSuccess === true) {
            const logicalAnswers = QuestionnaireUtils.getLogicalAnswers(questionnaire, answer);

            if (logicalAnswers.length > 0) {
                await Promise.all(logicalAnswers.map(async (logicalAnswer) => {
                    const existsAnswer = questionnaire.facultyQuests.find(p =>
                        p.questionno === logicalAnswer.facultyQuestDTO.questionno && p.answer.includes(logicalAnswer.facultyQuestDTO.answer));

                    // if (existsAnswer) {
                    //     await this.delete(existsAnswer, isTiered);
                    //     questionnaire.facultyQuests.splice(questionnaire.facultyQuests.indexOf(existsAnswer), 1);
                    // }

                    if (!existsAnswer && logicalAnswer.eventType === EventType.ADD) {
                        const createdAnswer = await this.create(logicalAnswer.facultyQuestDTO, isTiered, ppqInitiation);

                        if (createdAnswer) {
                            questionnaire.facultyQuests.push(createdAnswer);
                        }
                    }
                }));
            }

            await this.deleteNextQuestionAnswers(questionnaire, answer, isTiered, eventType);
        }

        if (question?.id === 40 || question?.id === 41) {
            await FacultyPPQDataService.updateAdditionalComment(questionnaire.facultyId, answer.answer);
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        return questionnaire;
    }

    async handleSubmitAnswer(questionnaire: QuestionnaireDTO, ppqInitiation?: boolean): Promise<{ isSuccess: boolean, message: string }> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const { facultyId, ppqyear, facultyQuests } = questionnaire;

        const isPPQPDFUploadSuccess = await ADMINFileService.uploadPPQPDF(facultyId);

        if (isPPQPDFUploadSuccess) {
            const response = await this.submit(facultyId, facultyQuests, ppqyear, ppqInitiation);

            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            return response;
        } else {
            const errorMessage = 'Failed to upload PPQ PDF.';
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, errorMessage);

            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            return { isSuccess: false, message: errorMessage };
        }
    }

    private async create(facultyQuest: FacultyQuestDTO, isTiered: boolean, ppqInitiation?: boolean): Promise<FacultyQuestDTO | null> {
        facultyQuest.ppqyear = new Date().getFullYear();

        const body = {
            ppqInitiation: ppqInitiation ? true : false,
            facultyQuest: facultyQuest,
        };

        const createAnswerURL = isTiered ? `adminquestionnaire/createsubmitted` :
            `adminquestionnaire/createsaved`;
        const response = await this.PostAsync<FacultyQuestDTO>(createAnswerURL, body);

        if (response.isSuccess) {
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    private async delete(facultyQuest: FacultyQuestDTO, isTiered: boolean): Promise<boolean> {
        const deleteAnswerURL = isTiered ? `adminquestionnaire/deletesubmitted` :
            `adminquestionnaire/deletesaved`;
        const response = await this.DeleteAsync<null>(`${deleteAnswerURL}/${facultyQuest.id}`);
        return response.isSuccess;
    }

    private async submit(facultyid: number, facultyQuests: FacultyQuestDTO[], ppqyear?: number, ppqInitiation?: boolean): Promise<{ isSuccess: boolean, message: string }> {
        const body = {
            facultyid,
            ppqyear: new Date().getFullYear(),
            ppqInitiation: ppqInitiation ? true : false,
            answers: facultyQuests,
        };
        const response = await this.PostAsync<boolean>(`adminquestionnaire/submit`, body);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
        }
        else {
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, response.message);
        }

        return { isSuccess: response.isSuccess, message: response.message };
    }

    async getAllSignOfAnswers(): Promise<FacultyQuestDTO[]> {
        const response = await this.GetAsync<FacultyQuestDTO[]>(`adminquestionnaire/getsignofquestionanswers`);

        let facultyQuests: FacultyQuestDTO[] = [];

        if (response.isSuccess) {
            facultyQuests = response.data;
        }

        return facultyQuests;
    }

    private deleteNextQuestionAnswers = async (questionnaire: QuestionnaireDTO, answer: FacultyQuestDTO, isTiered: boolean, eventType: EventType) => {
        const answers = QuestionnaireUtils.getAnswersToDelete(questionnaire, answer, eventType);

        await Promise.all(answers.map(async (existsAnswer) => {
            await this.delete(existsAnswer, isTiered);
            questionnaire.facultyQuests.splice(questionnaire.facultyQuests.indexOf(existsAnswer), 1);
        }));
    }
}

export default new ADMINQuestionnaireService();